import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';

import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { OnboardingSteps } from '@/models/Enums';

const ReleaseDetailsPage = ({ isReleaseFlow }: { isReleaseFlow?: boolean }) => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();

  const { setOnboardingStep, onboardingStep } = useOnboardingContext();

  const { setValue, watch } = useFormContext();
  const releaseName = watch('releaseDetails.releaseTitle');

  return (
    <>
      {breakpointHit && (
        <div className="text-left ml-16">
          <Button
            className="no-bg text-lef m0 pl0"
            onClick={() => {
              setOnboardingStep(onboardingStep - 1);
            }}
          >
            <Icon>chevron_left</Icon>
            {t('COMMON.BACK')}
          </Button>
        </div>
      )}
      <form>
        <h2 className={`${breakpointHit ? 'mt10' : 'mt40'} max-w1000 ml-auto mr-auto`}>
          {t('ONBOARDING.A-NEW-TRACK-EXCITING')}
        </h2>
        <h2 className="mb48 max-w1000 ml-auto mr-auto">{t('ONBOARDING.WE-CAN-HELP-WITH-THAT')}</h2>

        <div className="max-w400 ml-auto mr-auto mt20">
          <div className="text-left">
            <label>
              <p className="mt20">{t('ONBOARDING.SONG-NAME')}</p>
              <input
                placeholder="Track name"
                name="releaseName"
                value={releaseName}
                data-testid="release-name-input"
                onChange={(e) => {
                  setValue('releaseDetails.releaseTitle', e.target.value);
                }}
              />
            </label>
          </div>
        </div>

        <div className="onboarding-footer">
          <div className="d-flex jc-end mt40">
            {!breakpointHit && (
              <Button
                className="border-btn min-w130"
                onClick={() => {
                  if (isReleaseFlow) {
                    return setOnboardingStep(OnboardingSteps.RELEASE_TYPE_CHOICE);
                  }
                  setOnboardingStep(onboardingStep - 1);
                }}
              >
                {t('COMMON.BACK')}
              </Button>
            )}
            <Button
              className={`btn-white min-w130 ${breakpointHit ? 'w100p' : ''}`}
              disabled={!releaseName}
              type="submit"
              data-testid="continue-button"
              onClick={() => {
                setOnboardingStep(OnboardingSteps.RELEASE_DATE);
              }}
            >
              {t('COMMON.CONTINUE')}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ReleaseDetailsPage;
