import React, { useEffect, useState } from 'react';

import { A11y, Autoplay, EffectCoverflow, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import useArtist from '@/hooks/artist/useArtist';
import useReleaseCycles from '@/hooks/release-cycles/useReleaseCycles';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { ReleaseCycleModel } from '@/models/ReleaseCycles';

import { HomeScreenFeatureBanner } from '../cards/HomeScreenFeatureBanner';
import Loading from '../utility/Loading';
import { ReleaseCycleCard } from './ReleaseCycleCard';
import { ReleaseCycleButton } from './ReleaseCycleFlow';

export const HomeScreenReleaseCycles = () => {
  const [cycles, setCycles] = useState<ReleaseCycleModel[]>([]);
  const { artist } = useArtist();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { breakpointHit, breakpointHitCustom } = useBreakpoints(1475);
  const { releaseCycles, refetchReleaseCycles } = useReleaseCycles({});
  const [expanded, setExpanded] = useState<{ releaseId: string; isMinimize: boolean }[]>([]);

  useEffect(() => {
    if (releaseCycles) {
      setCycles(releaseCycles);
      setIsLoading(false);
      if (breakpointHitCustom) {
        setExpanded(
          releaseCycles.map((item, index) => ({ releaseId: item.id, isMinimize: index === 0 ? false : true }))
        );
      } else {
        setExpanded(releaseCycles.map((item) => ({ releaseId: item.id, isMinimize: false })));
      }
    }
  }, [breakpointHitCustom, releaseCycles]);

  if (isLoading) {
    return (
      <div className="centered-loading">
        <Loading size="small" />
      </div>
    );
  }

  if (breakpointHit) {
    return (
      <div id="home-screen-release-cycles">
        <Swiper
          className={`br10 pb40`}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={10}
          slidesPerView={1.1}
          navigation={false}
          pagination={true}
          centeredSlides={false}
          speed={2000}
        >
          {cycles &&
            !isLoading &&
            cycles?.map((cycle) => (
              <SwiperSlide key={cycle.id} className={`pos-rel min-w320`}>
                <ReleaseCycleCard
                  releaseCycle={cycle}
                  minimize={true}
                  setExpanded={(output: boolean) => {
                    const index = expanded.findIndex((item) => item.releaseId === cycles[0].id);
                    if (index === -1) {
                      setExpanded([...expanded, { releaseId: cycles[0].id, isMinimize: true }]);
                    } else {
                      expanded[index].isMinimize = output;
                      setExpanded([...expanded]);
                    }
                  }}
                />
              </SwiperSlide>
            ))}

          {cycles && cycles.length !== 2 && (
            <SwiperSlide className={`pos-rel min-w320`}>
              <ReleaseCycleButton entryType="card-one" refetch={refetchReleaseCycles} />
            </SwiperSlide>
          )}

          <SwiperSlide className={`pos-rel min-w320`}>
            <HomeScreenFeatureBanner isOne isPlaylisting />
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }

  if ((!cycles || cycles.length === 0) && !isLoading) {
    return (
      <div id="home-screen-release-cycles" className="d-flex max-w1920">
        {!artist && <ReleaseCycleButton entryType="card-one" refetch={refetchReleaseCycles} />}
        {artist && (
          <Swiper
            className={`br10`}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow]}
            spaceBetween={10}
            slidesPerView={1}
            navigation={false}
            effect="coverflow"
            centeredSlides={true}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            speed={2000}
          >
            <SwiperSlide className={`w100p pos-rel ${breakpointHit ? 'max-w400' : ''}`}>
              <ReleaseCycleButton entryType="card-one" refetch={refetchReleaseCycles} />
            </SwiperSlide>

            <SwiperSlide className={`w100p pos-rel ${breakpointHit ? 'max-w400' : ''}`}>
              <HomeScreenFeatureBanner isOne isPlaylisting />
            </SwiperSlide>
          </Swiper>
        )}
      </div>
    );
  }

  if (cycles && cycles.length === 1) {
    return (
      <div id="home-screen-release-cycles" className="d-flex gap20 max-w1920 overflow-scroll">
        <ReleaseCycleCard
          releaseCycle={cycles[0]}
          minimize={expanded.find((item) => item.releaseId === cycles[0].id)?.isMinimize}
          setExpanded={(output: boolean) => {
            const index = expanded.findIndex((item) => item.releaseId === cycles[0].id);
            if (index === -1) {
              setExpanded([...expanded, { releaseId: cycles[0].id, isMinimize: output }]);
            } else {
              expanded[index].isMinimize = output;
              setExpanded([...expanded]);
            }
          }}
        />
        <div className="min-h396 min-w320 flex-basis flex-grow">
          {!artist && <ReleaseCycleButton entryType="card" refetch={refetchReleaseCycles} />}
          {artist && (
            <Swiper
              className={`br10`}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow]}
              spaceBetween={10}
              slidesPerView={1}
              effect="coverflow"
              navigation={false}
              centeredSlides={true}
              loop={true}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              speed={2000}
            >
              <SwiperSlide className={`w100p pos-rel`}>
                <ReleaseCycleButton entryType="card" refetch={refetchReleaseCycles} />
              </SwiperSlide>

              <SwiperSlide className={`w100p pos-rel`}>
                <HomeScreenFeatureBanner isPlaylisting />
              </SwiperSlide>
            </Swiper>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      id="home-screen-release-cycles"
      className={`d-flex gap20 max-w1920 ${breakpointHitCustom ? 'overflow-scroll' : ''}`}
    >
      {cycles &&
        !isLoading &&
        cycles?.map((cycle) => (
          <ReleaseCycleCard
            key={cycle.id}
            releaseCycle={cycle}
            minimize={breakpointHit || expanded.find((item) => item.releaseId === cycle.id)?.isMinimize}
            setExpanded={(output: boolean) => {
              const index = expanded.findIndex((item) => item.releaseId === cycle.id);
              if (breakpointHitCustom) {
                expanded.forEach((item) => {
                  if (item.releaseId !== cycle.id) {
                    item.isMinimize = true;
                  }
                });
              }
              if (index === -1) {
                setExpanded([...expanded, { releaseId: cycle.id, isMinimize: output }]);
              } else {
                expanded[index].isMinimize = output;
                setExpanded([...expanded]);
              }
            }}
          />
        ))}
    </div>
  );
};
