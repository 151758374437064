import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, CircularProgress, Icon, Modal } from '@mui/material';

import { useActivityActionHandler } from '@/constants/ActivityActionHandler';
import { Images } from '@/constants/Images';
import { ReleaseCategoryNameFormatter } from '@/formatters/ReleaseCycleFormatters';
import useSubscription from '@/hooks/account/useSubscription';
import useAccountContext from '@/hooks/context/useAccountContext';
import useAnimationContext from '@/hooks/context/useAnimationContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useReleaseCycles from '@/hooks/release-cycles/useReleaseCycles';
import useReleaseCycleSchedule from '@/hooks/release-cycles/useReleaseCycleSchedule';
import useReleaseCycleTaskCompletionStats from '@/hooks/release-cycles/useReleaseCycleTaskCompletionStats';
import useUserTracking from '@/hooks/useUserTracking';
import { ScheduleTaskModel } from '@/models/ReleaseCycles';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';

import ButtonComponent from '../utility/microcomponents/Button';
import Card from '../utility/microcomponents/Card';
import DialogModal from '../utility/modals/DialogModal';
import SaveWithProBadge from '../utility/statuses/SaveWithProBadge';
import TaskPill from '../utility/statuses/TaskPill';
import ReleaseCycleTaskCategoryStatus from './ReleaseCycleTaskCategoryStatus';

export const ScheduleTaskCard = ({
  task,
  date,
  index,
  isWeek,
  isWeekMobile,
  isCard,
  updateList,
}: {
  task: ScheduleTaskModel;
  date: string;
  index?: number;
  isWeek?: boolean;
  isWeekMobile?: boolean;
  isCard?: boolean;
  updateList: (id: string) => void;
}) => {
  const { accountId } = useAccountContext();
  const navigate = useNavigate();
  const { dispatchSnackbar } = useSnackbarContext();
  const { fireAnimation } = useAnimationContext();
  const { t } = useTranslation();
  const [params, setSearchParams] = useSearchParams();
  const { isSubscribed } = useSubscription();
  const { handleActivity } = useActivityActionHandler(task.button.url || '');
  const userTracking = useUserTracking();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { releaseCycles, refetchReleaseCycles } = useReleaseCycles({});
  const { refetchReleaseCycles: refetchPreviousReleases } = useReleaseCycles({ isCompleted: true });

  const releaseDetails = releaseCycles?.find((release) => release.id === task.releaseCycleId);

  const { refetchReleaseCyclesCompletionStats } = useReleaseCycleTaskCompletionStats({
    scheduleId: task.releaseCycleId,
  });
  const { refetchReleaseCycleSchedule } = useReleaseCycleSchedule({
    scheduleId: task.releaseCycleId,
  });

  const { refetchReleaseCyclesCompletionStats: refetchAllStats } = useReleaseCycleTaskCompletionStats({
    scheduleId: '',
  });
  const { refetchReleaseCycleSchedule: refetchAllCycles } = useReleaseCycleSchedule({
    scheduleId: '',
  });

  const [categoryName, setCategoryName] = useState<string>();

  useEffect(() => {
    if (task.category.value !== undefined) {
      setCategoryName(ReleaseCategoryNameFormatter(task.category.value));
    }
  }, [task.category.value]);

  const markAsComplete = useCallback(async () => {
    setIsLoading(true);
    if (!accountId) return;

    ReleaseCyclesAPI.markTaskAsComplete({
      scheduleId: task.releaseCycleId,
      taskId: task.id,
      date,
      data: { accountId: accountId, isCompleted: !task.isCompleted },
    })
      .then(async () => {
        task.isCompleted = !task.isCompleted;
        if (!task.isCompleted) {
          userTracking?.userReactivatedReleaseCycleTask(task, date);
        } else {
          userTracking?.userCompletedReleaseCycleTask(task, date);
        }
        updateList(task.id);
        await refetchReleaseCyclesCompletionStats();
        await refetchReleaseCycleSchedule();
        await refetchAllStats();
        await refetchAllCycles();
        setIsModalOpen(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [
    accountId,
    task,
    date,
    userTracking,
    updateList,
    refetchReleaseCyclesCompletionStats,
    refetchReleaseCycleSchedule,
    refetchAllStats,
    refetchAllCycles,
  ]);

  const completeRelease = useCallback(async () => {
    if (!task.releaseCycleId || !accountId) return;
    setIsLoading(true);
    ReleaseCyclesAPI.completeReleaseCycle({ scheduleId: task.releaseCycleId, accountId: accountId })
      .then(async () => {
        await refetchReleaseCycles();
        await refetchReleaseCyclesCompletionStats();
        await refetchPreviousReleases();
        navigate('/release-complete');
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [
    accountId,
    navigate,
    refetchPreviousReleases,
    refetchReleaseCycles,
    refetchReleaseCyclesCompletionStats,
    task.releaseCycleId,
  ]);

  useEffect(() => {
    if (isModalOpen) {
      userTracking?.userOpenedReleaseCycleTask(task, date);
    }
  }, [date, isModalOpen, task, userTracking]);

  const trackClickThrough = useCallback(() => {
    userTracking?.userClickedThroughReleaseCycleTask(task, date);
  }, [date, task, userTracking]);

  const handleLinkClick = useCallback(async () => {
    trackClickThrough();
    if (task.category.value === 4) {
      setIsDialogOpen(true);
    }
    if (task.button.url) {
      if (task.button.promotionCode && isSubscribed) {
        await navigator.clipboard.writeText(task.button.promotionCode);
        await dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: task.button.promotionCode + ' ' + t('COMMON.COPIED-TO-CLIPBOARD'),
            type: 'success',
          },
        });
        setTimeout(() => {
          return handleActivity();
        }, 1000);
      } else if (!isSubscribed && task.saveWithPro) {
        if (isModalOpen) {
          return handleActivity();
        }
        return setIsModalOpen(true);
      } else {
        return handleActivity();
      }
    }
  }, [
    dispatchSnackbar,
    handleActivity,
    isModalOpen,
    isSubscribed,
    t,
    task.button.promotionCode,
    task.button.url,
    task.category.value,
    task.saveWithPro,
    trackClickThrough,
  ]);

  if (isWeek) {
    return (
      <>
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="to-do-modal">
            <Button className="icon-btn m0 mt-10 mr-10 pos-abs r16" onClick={() => setIsModalOpen(false)}>
              <Icon>close</Icon>
            </Button>
            <h5 className="">{task.title}</h5>
            <ReleaseCycleTaskCategoryStatus
              category={task.category.value}
              time={task.timeEstimateMinutes}
              isModal
              isOneTimeTask={task.isOneTimeTask}
              isTimeSensitive={task.isTimeSensitive}
            />
            {task.videoUrl && (
              <div className="wrapper mb20 br12 mt10">
                <div className="h_iframe br10">
                  <iframe src={task.videoUrl} width={'100%'} height={'100%'} className="br10" allowFullScreen />
                </div>
              </div>
            )}
            {!task.videoUrl && (
              <div className="w100p h150 mt20 pos-rel">
                <object
                  className="playlist-image br8 object-fit-cover"
                  data={task.images.desktop.detailUrl}
                  type="image/png"
                  height={150}
                  width={'100%'}
                >
                  <img src={Images.unhurdFallback} alt={task.title} className="br8 object-fit-cover" />
                </object>
              </div>
            )}
            <p className="mt20 text-faded">{task.body}</p>
            {!isSubscribed && task.saveWithPro && (
              <div className="d-flex mt20 gap10">
                <Button
                  className="btn-white w100p m0 flex-grow flex-basis"
                  onClick={() => {
                    handleLinkClick();
                  }}
                >
                  {task.button.text || t('COMMON.CLICK-HERE')}
                </Button>
                <p className="text-faded mt-auto mb-auto">or</p>
                <ButtonComponent
                  isCustom
                  className="m0 p0 flex-grow flex-basis w100p"
                  onClick={() => {
                    params.set('subscription', 'true');
                    return setSearchParams(params);
                  }}
                >
                  <div className="m0 br10 subscription-banner text-white pt8 pb10 pl16 pr16 border-purple overflow-visible w100p">
                    <div className="abs-center bg-blue-gradient-faded t-12 p2 z-1000 br4">
                      <p className="text-blue-gradient small pt0 min-w115">SAVE WITH PRO</p>
                    </div>

                    <h5 className="text-purple">{t('Unlock discount')}</h5>
                  </div>
                </ButtonComponent>
              </div>
            )}
            {!(!isSubscribed && task.saveWithPro) && (
              <Button
                className="btn-white w100p m0 mt20"
                onClick={() => {
                  handleLinkClick();
                }}
              >
                {task.button.text || t('COMMON.CLICK-HERE')}
              </Button>
            )}
            {!task.isCompleted && task.isProOnly && !isSubscribed && (
              <Button
                className="btn-white w100p m0 mt20"
                onClick={() => {
                  params.set('subscription', 'true');
                  return setSearchParams(params);
                }}
              >
                {t('Unlock with pro')}
              </Button>
            )}

            {task.category.value !== 4 && (
              <Button className="btn-grey w100p m0 mt8" onClick={markAsComplete} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={16} />
                ) : task.isCompleted ? (
                  <span className="d-flex">
                    <Icon className="material-symbols-outlined fs-16 mt2">highlight_off</Icon>
                    <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-INCOMPLETE')}</span>
                  </span>
                ) : (
                  <span className="d-flex">
                    <Icon className="material-symbols-outlined fs-16 mt2">check_circle</Icon>
                    <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-DONE')}</span>
                  </span>
                )}
              </Button>
            )}
          </div>
        </Modal>
        <Card
          innerInner
          key={index}
          className="p10 text-left mb8 cursor-pointer"
          style={{ '--animation-number': `${index}` } as CSSProperties}
          onClick={() => setIsModalOpen(true)}
        >
          <ReleaseCycleTaskCategoryStatus
            category={(task as ScheduleTaskModel).category.value}
            time={(task as ScheduleTaskModel).timeEstimateMinutes}
          />
          <p className={`mt8 ${task.isCompleted ? 'text-faded strikethrough' : ''}`}>{task.title}</p>
          {releaseCycles && releaseCycles?.length > 1 && (
            <p className="text-faded small ">Release: {releaseDetails?.name}</p>
          )}

          {!task.isCompleted && (
            <>
              {task.isOneTimeTask && (
                <div className="mt10">
                  <TaskPill isOneTimeTask={task.isOneTimeTask} />
                </div>
              )}
              {task.isTimeSensitive && (
                <div className="mt10">
                  <TaskPill isTimeSensitive={task.isTimeSensitive} />
                </div>
              )}
            </>
          )}
        </Card>
        <DialogModal
          title={t('RELEASE-CYCLES.END-RELEASE-CYCLE')}
          content={t('RELEASE-CYCLES.END-RELEASE-CYCLE-CONFIRMATION')}
          open={isDialogOpen}
          output={(output) => {
            if (output) {
              markAsComplete();
              completeRelease();
              fireAnimation('confetti');
            }
            setIsDialogOpen(false);
          }}
          isEndReleaseCycle
        />
      </>
    );
  }

  if (isWeekMobile) {
    return (
      <>
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          style={{ '--scrollbar-width': '0px' } as CSSProperties}
        >
          <div className="to-do-modal h100p p0 pos-rel mt-24">
            <div className="h100p overflow-scroll">
              <Button className="icon-btn m0 pos-abs t20 l16 z-1000 br50p" onClick={() => setIsModalOpen(false)}>
                <Icon>chevron_left</Icon>
              </Button>
              {task.videoUrl && (
                <div className="wrapper mb20 br12 mt10 pos-abs t60 l0">
                  <div className="h_iframe br10">
                    <iframe src={task.videoUrl} width={'100%'} height={'100%'} className="br10" allowFullScreen />
                  </div>
                </div>
              )}
              {!task.videoUrl && (
                <img
                  src={task.images.desktop.previewUrl}
                  alt={task.title}
                  className="object-fit-cover w100p br0 scale-1-1 pos-abs t0 l0"
                />
              )}
              {task.saveWithPro && (
                <div className="pos-abs r16 t16">
                  <SaveWithProBadge />
                </div>
              )}
              <div className="pos-rel z-1000 mt380">
                <div className="mt-50 z-1000">
                  <div className="p20 bg-blur">
                    <h5 className={` ${task.isCompleted ? 'text-faded strikethrough' : ''}`}>{task.title}</h5>
                    {releaseCycles && releaseCycles?.length > 1 && (
                      <p className="text-faded small ">Release: {releaseDetails?.name}</p>
                    )}

                    <ReleaseCycleTaskCategoryStatus
                      category={task.category.value}
                      time={task.timeEstimateMinutes}
                      isModal
                      isOneTimeTask={task.isOneTimeTask}
                      isTimeSensitive={task.isTimeSensitive}
                    />
                  </div>
                  <div className="p20 pb200 bg-black z-1000">
                    <p className="text-faded">{task.body}</p>
                  </div>
                </div>
              </div>
              <div className="pos-abs b20 w100p p0 z-2000 bg-black-gradient-v pt30 mt20">
                {!isSubscribed && task.saveWithPro && (
                  <ButtonComponent
                    isCustom
                    className="m0 pl10 pr10 pb0 flex-grow flex-basis w100p"
                    onClick={() => {
                      params.set('subscription', 'true');
                      return setSearchParams(params);
                    }}
                  >
                    <div className="m0 br10 subscription-banner text-white pt8 pb10 pl16 pr16 border-purple overflow-visible w100p">
                      <div className="abs-center bg-blue-gradient-faded t-12 p2 z-1000 br4">
                        <p className="text-blue-gradient small pt0">SAVE WITH PRO</p>
                      </div>

                      <h5 className="text-purple">{t('Unlock discount')}</h5>
                    </div>
                  </ButtonComponent>
                )}
                <div className="p10">
                  {!task.isCompleted && !(task.isProOnly && !isSubscribed) && (
                    <Button
                      className="btn-white w100p m0"
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      {task.button.text || t('COMMON.CLICK-HERE')}
                    </Button>
                  )}
                  {!task.isCompleted && task.isProOnly && !isSubscribed && (
                    <Button
                      className="btn-white w100p m0"
                      onClick={() => {
                        params.set('subscription', 'true');
                        return setSearchParams(params);
                      }}
                    >
                      {t('Unlock with pro')}
                    </Button>
                  )}

                  {task.category.value !== 4 && (
                    <Button className="btn-grey w100p m0 mt8" onClick={markAsComplete} disabled={isLoading}>
                      {isLoading ? (
                        <CircularProgress size={16} />
                      ) : task.isCompleted ? (
                        <span className="d-flex">
                          <Icon className="material-symbols-outlined fs-16 mt2">highlight_off</Icon>
                          <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-INCOMPLETE')}</span>
                        </span>
                      ) : (
                        <span className="d-flex">
                          <Icon className="material-symbols-outlined fs-16 mt2">check_circle</Icon>
                          <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-DONE')}</span>
                        </span>
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Card
          innerInner
          key={index}
          className="p10 text-left mb8 cursor-pointer"
          style={{ '--animation-number': `${index}` } as CSSProperties}
          onClick={() => setIsModalOpen(true)}
        >
          <div className={`left-border dot-task-types ${categoryName} pl10`}>
            <div className="d-flex">
              <div>
                <h5 className={`${task.isCompleted ? 'text-faded strikethrough' : ''}`}>{task.title}</h5>
                {releaseCycles && releaseCycles?.length > 1 && (
                  <p className="text-faded small">Release: {releaseDetails?.name}</p>
                )}
              </div>
              <Icon className="ml-auto text-white">chevron_right</Icon>
            </div>
            <div className="d-flex gap10 jc-space-between mt10">
              <div className="mt-auto mb-auto">
                <ReleaseCycleTaskCategoryStatus time={(task as ScheduleTaskModel).timeEstimateMinutes} />
              </div>

              <div className="ml-auto d-flex gap10 jc-end">
                {!task.isCompleted && (
                  <>
                    {task.isOneTimeTask && (
                      <div className="">
                        <TaskPill isOneTimeTask={task.isOneTimeTask} />
                      </div>
                    )}
                    {task.isTimeSensitive && (
                      <div className="">
                        <TaskPill isTimeSensitive={task.isTimeSensitive} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Card>
        <DialogModal
          title={t('RELEASE-CYCLES.END-RELEASE-CYCLE')}
          content={t('RELEASE-CYCLES.END-RELEASE-CYCLE-CONFIRMATION')}
          open={isDialogOpen}
          output={(output) => {
            if (output) {
              markAsComplete();
              completeRelease();
              fireAnimation('confetti');
            }
            setIsDialogOpen(false);
          }}
          isEndReleaseCycle
        />
      </>
    );
  }

  if (isCard) {
    return (
      <>
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          style={{ '--scrollbar-width': '0px' } as CSSProperties}
        >
          <div className="to-do-modal h100p p0 pos-rel mt-24">
            <div className="h100p overflow-scroll">
              <Button className="icon-btn m0 pos-abs t20 l16 z-1000 br50p" onClick={() => setIsModalOpen(false)}>
                <Icon>chevron_left</Icon>
              </Button>
              {task.videoUrl && (
                <div className="wrapper mb20 br12 mt10 pos-abs t60 l0">
                  <div className="h_iframe br10">
                    <iframe src={task.videoUrl} width={'100%'} height={'100%'} className="br10" allowFullScreen />
                  </div>
                </div>
              )}
              {!task.videoUrl && (
                <img
                  src={task.images.desktop.previewUrl}
                  alt={task.title}
                  className="object-fit-cover w100p br0 scale-1-1 pos-abs t0 l0"
                />
              )}
              {task.saveWithPro && (
                <div className="pos-abs r16 t16">
                  <SaveWithProBadge />
                </div>
              )}
              <div className="pos-rel z-1000 mt380">
                <div className="mt-50 z-1000">
                  <div className="p20 bg-blur">
                    <h5 className={` ${task.isCompleted ? 'text-faded strikethrough' : ''}`}>{task.title}</h5>
                    {releaseCycles && releaseCycles?.length > 1 && (
                      <p className="text-faded small ">Release: {releaseDetails?.name}</p>
                    )}

                    <ReleaseCycleTaskCategoryStatus
                      category={task.category.value}
                      time={task.timeEstimateMinutes}
                      isModal
                      isOneTimeTask={task.isOneTimeTask}
                      isTimeSensitive={task.isTimeSensitive}
                    />
                  </div>
                  <div className="p20 pb200 bg-black z-1000">
                    <p className="text-faded">{task.body}</p>
                  </div>
                </div>
              </div>
              <div className="pos-abs b20 w100p p0 z-2000 bg-black-gradient-v pt30 mt20">
                {!isSubscribed && task.saveWithPro && (
                  <ButtonComponent
                    isCustom
                    className="m0 pl10 pr10 pb0 flex-grow flex-basis w100p"
                    onClick={() => {
                      params.set('subscription', 'true');
                      return setSearchParams(params);
                    }}
                  >
                    <div className="m0 br10 subscription-banner text-white pt8 pb10 pl16 pr16 border-purple overflow-visible w100p">
                      <div className="abs-center bg-blue-gradient-faded t-12 p2 z-1000 br4">
                        <p className="text-blue-gradient small pt0">SAVE WITH PRO</p>
                      </div>

                      <h5 className="text-purple">{t('Unlock discount')}</h5>
                    </div>
                  </ButtonComponent>
                )}
                <div className="p10">
                  {!task.isCompleted && !(task.isProOnly && !isSubscribed) && (
                    <Button
                      className="btn-white w100p m0"
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      {task.button.text || t('COMMON.CLICK-HERE')}
                    </Button>
                  )}
                  {!task.isCompleted && task.isProOnly && !isSubscribed && (
                    <Button
                      className="btn-white w100p m0"
                      onClick={() => {
                        params.set('subscription', 'true');
                        return setSearchParams(params);
                      }}
                    >
                      {t('Unlock with pro')}
                    </Button>
                  )}

                  {task.category.value !== 4 && (
                    <Button className="btn-grey w100p m0 mt8" onClick={markAsComplete} disabled={isLoading}>
                      {isLoading ? (
                        <CircularProgress size={16} />
                      ) : task.isCompleted ? (
                        <span className="d-flex">
                          <Icon className="material-symbols-outlined fs-16 mt2">highlight_off</Icon>
                          <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-INCOMPLETE')}</span>
                        </span>
                      ) : (
                        <span className="d-flex">
                          <Icon className="material-symbols-outlined fs-16 mt2">check_circle</Icon>
                          <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-DONE')}</span>
                        </span>
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Card
          innerInner
          key={index}
          className="p10 text-left cursor-pointer min-h300 pos-rel min-w300 max-w300"
          style={
            {
              '--animation-number': `${index}`,
              backgroundImage: `url(${task.images.desktop.previewUrl})`,
              objectFit: 'cover',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            } as CSSProperties
          }
          onClick={() => setIsModalOpen(true)}
        >
          {task.saveWithPro && (
            <div className="pos-abs l8 t8">
              <SaveWithProBadge />
            </div>
          )}
          <div className="card-inner-inner p10 pos-abs b0 w100p bg-blur br-b-12 br-t-0 text-left l0">
            <div className="d-flex">
              <div>
                <p className={`${task.isCompleted ? 'text-faded strikethrough' : ''}`}>{task.title}</p>
                {releaseCycles && releaseCycles?.length > 1 && (
                  <p className="text-faded small ">Release: {releaseDetails?.name}</p>
                )}
              </div>
              <Icon className="ml-auto text-white">chevron_right</Icon>
            </div>
            <div className="d-flex jc-space-between">
              <div className="mt-auto mb-auto">
                <ReleaseCycleTaskCategoryStatus time={(task as ScheduleTaskModel).timeEstimateMinutes} />
              </div>

              {!task.isCompleted && (
                <>
                  {task.isOneTimeTask && (
                    <div className="mt10">
                      <TaskPill isOneTimeTask={task.isOneTimeTask} />
                    </div>
                  )}
                  {task.isTimeSensitive && (
                    <div className="mt10">
                      <TaskPill isTimeSensitive={task.isTimeSensitive} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Card>
        <DialogModal
          title={t('RELEASE-CYCLES.END-RELEASE-CYCLE')}
          content={t('RELEASE-CYCLES.END-RELEASE-CYCLE-CONFIRMATION')}
          open={isDialogOpen}
          output={(output) => {
            if (output) {
              markAsComplete();
              completeRelease();
              fireAnimation('confetti');
            }
            setIsDialogOpen(false);
          }}
          isEndReleaseCycle
        />
      </>
    );
  }

  return (
    <>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="to-do-modal">
          <Button className="icon-btn m0 mt-10 mr-10 pos-abs r16" onClick={() => setIsModalOpen(false)}>
            <Icon>close</Icon>
          </Button>
          <h5 className="">{task.title}</h5>
          <ReleaseCycleTaskCategoryStatus
            category={task.category.value}
            time={task.timeEstimateMinutes}
            isModal
            isOneTimeTask={task.isOneTimeTask}
            isTimeSensitive={task.isTimeSensitive}
          />
          <div className="w100p h150 mt20 pos-rel">
            <object
              className="playlist-image br8 object-fit-cover"
              data={task.images.desktop.detailUrl}
              type="image/png"
              height={150}
              width={'100%'}
            >
              <img src={Images.unhurdFallback} alt={task.title} className="br8 object-fit-cover" />
            </object>
          </div>
          <p className="mt20 text-faded">{task.body}</p>
          {!isSubscribed && task.saveWithPro && (
            <div className="d-flex mt20 gap10">
              <Button
                className="btn-white w100p m0 flex-grow flex-basis"
                onClick={() => {
                  handleLinkClick();
                }}
              >
                {task.button.text || t('COMMON.CLICK-HERE')}
              </Button>
              <p className="text-faded mt-auto mb-auto">or</p>
              <ButtonComponent
                isCustom
                className="m0 p0 flex-grow flex-basis w100p"
                onClick={() => {
                  params.set('subscription', 'true');
                  return setSearchParams(params);
                }}
              >
                <div className="m0 br10 subscription-banner text-white pt8 pb10 pl16 pr16 border-purple overflow-visible w100p">
                  <div className="abs-center bg-blue-gradient-faded t-12 p2 z-1000 br4">
                    <p className="text-blue-gradient small pt0 min-w115">SAVE WITH PRO</p>
                  </div>

                  <h5 className="text-purple">{t('Unlock discount')}</h5>
                </div>
              </ButtonComponent>
            </div>
          )}
          {!task.isCompleted && isSubscribed && (
            <Button
              className="btn-white w100p m0 mt20"
              onClick={() => {
                handleLinkClick();
              }}
            >
              {task.button.text || t('COMMON.CLICK-HERE')}
            </Button>
          )}
          {!task.isCompleted && task.isProOnly && !isSubscribed && (
            <Button
              className="btn-white min-w200 m0"
              onClick={() => {
                params.set('subscription', 'true');
                return setSearchParams(params);
              }}
            >
              {t('Unlock with pro')}
            </Button>
          )}

          {task.category.value !== 4 && (
            <Button className="btn-grey w100p m0 mt8" onClick={markAsComplete} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={16} />
              ) : task.isCompleted ? (
                <span className="d-flex">
                  <Icon className="material-symbols-outlined fs-16 mt2">highlight_off</Icon>
                  <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-INCOMPLETE')}</span>
                </span>
              ) : (
                <span className="d-flex">
                  <Icon className="material-symbols-outlined fs-16 mt2">check_circle</Icon>
                  <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-DONE')}</span>
                </span>
              )}
            </Button>
          )}
        </div>
      </Modal>
      <Card
        key={index}
        innerInner
        className={`p10 pos-rel dynamic-card-transition ${task.isCompleted ? 'collapsed' : 'expanded'}`}
        style={{ '--animation-number': `${index}` } as React.CSSProperties}
      >
        {!task.isCompleted && task.videoUrl && (
          <div className="wrapper mb20 br12">
            <div className="h_iframe br10">
              <iframe src={task.videoUrl} width={'100%'} height={'100%'} className="br10" allowFullScreen />
            </div>
          </div>
        )}
        <div className={`d-flex`}>
          <object className="playlist-image br8" data={task.images.desktop.previewUrl} type="image/png">
            <img src={Images.unhurdFallback} alt={task.title} className="br8 object-fit-cover" />
          </object>
          {task.saveWithPro && !task.isCompleted && (
            <div className="pos-abs l16 t16">
              <SaveWithProBadge />
            </div>
          )}
          <div className="ml20 pos-rel flex-grow">
            <div className={`left-border dot-task-types ${categoryName} pl10`}>
              <div className="d-flex jc-space-between">
                <div>
                  <h5 className={`${task.isCompleted ? 'text-faded strikethrough' : ''}`}>{task.title}</h5>
                  <div className="d-flex">
                    {releaseCycles && releaseCycles?.length > 1 && (
                      <p className="text-faded small ">Release: {releaseDetails?.name}</p>
                    )}
                    {releaseCycles && releaseCycles?.length > 1 && <p className="text-faded small pr8 pl8">|</p>}
                    <ReleaseCycleTaskCategoryStatus time={task.timeEstimateMinutes} />
                  </div>
                </div>
                {!task.isCompleted && (
                  <div className="d-flex gap8 ml-auto">
                    {task.isOneTimeTask && <TaskPill isOneTimeTask={task.isOneTimeTask} />}
                    {task.isTimeSensitive && <TaskPill isTimeSensitive={task.isTimeSensitive} />}
                  </div>
                )}
              </div>
              {!task.isCompleted && <p className="text-faded mt10 mb90">{task.body}</p>}
            </div>
            <div className="b0 l0 d-flex pos-abs w100p flex-wrap gap8 jc-end">
              {task.category.value !== 4 && (
                <Button className="btn-grey min-w200 m0" onClick={markAsComplete} disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress size={16} />
                  ) : task.isCompleted ? (
                    <span className="d-flex">
                      <Icon className="material-symbols-outlined fs-16 mt2">highlight_off</Icon>
                      <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-INCOMPLETE')}</span>
                    </span>
                  ) : (
                    <span className="d-flex">
                      <Icon className="material-symbols-outlined fs-16 mt2">check_circle</Icon>
                      <span className="mt-auto mb-auto pl8">{t('RELEASE-CYCLES.MARK-AS-DONE')}</span>
                    </span>
                  )}
                </Button>
              )}
              {!task.isCompleted && !(task.isProOnly && !isSubscribed) && (
                <Button
                  className="btn-white min-w200 m0"
                  onClick={() => {
                    handleLinkClick();
                  }}
                >
                  {task.button.text || t('COMMON.CLICK-HERE')}
                </Button>
              )}
              {!task.isCompleted && task.isProOnly && !isSubscribed && (
                <Button
                  className="btn-white min-w200 m0"
                  onClick={() => {
                    params.set('subscription', 'true');
                    return setSearchParams(params);
                  }}
                >
                  {t('Unlock with pro')}
                </Button>
              )}
            </div>
          </div>
          <DialogModal
            title={t('RELEASE-CYCLES.END-RELEASE-CYCLE')}
            content={t('RELEASE-CYCLES.END-RELEASE-CYCLE-CONFIRMATION')}
            open={isDialogOpen}
            output={(output) => {
              if (output) {
                markAsComplete();
                completeRelease();
                fireAnimation('confetti');
              }
              setIsDialogOpen(false);
            }}
            isEndReleaseCycle
          />
        </div>
      </Card>
    </>
  );
};
