import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, Icon, Modal } from '@mui/material';

import { CSATSurveyConfig } from '@/models/Surveys';
import useFeatureFlags from '@/services/FeatureFlag';

import { EmojiRatingInput } from './survey-inputs/EmojiRatingInput';

const CSATSurvey = ({
  isOpen,
  onClose,
  survey,
}: {
  isOpen: boolean;
  onClose: () => void;
  survey?: CSATSurveyConfig | null;
}) => {
  const { t } = useTranslation();
  const [params, setSearchParams] = useSearchParams();

  const [chosenOption, setChosenOption] = useState<string>();
  const [freeformText, setFreeformText] = useState<string>();

  const { captureEvent } = useFeatureFlags();

  const sendSurveyFeedback = () => {
    if (!survey || !chosenOption) return;
    captureEvent('survey sent', {
      $survey_id: survey.id,
      $survey_response: chosenOption,
      $survey_response_1: freeformText,
    });

    return closeModal();
  };

  const hasInteractedWithSurvey = () => {
    localStorage.setItem(`hasInteractedWithSurveyId${survey?.id}`, 'true');
    params.delete('showReleaseCycleEndSurvey');
    setSearchParams(params);
  };

  const closeModal = () => {
    clearInputs();
    hasInteractedWithSurvey();
    onClose();
  };

  const clearInputs = () => {
    setChosenOption(undefined);
    setFreeformText('');
  };

  const dismissSurvey = () => {
    if (!survey) return;
    captureEvent('survey dismissed', {
      $survey_id: survey.id,
    });

    return closeModal();
  };

  return (
    <>
      <Modal open={isOpen} onClose={dismissSurvey}>
        <div className="to-do-modal">
          <div className="d-flex jc-space-between mb16">
            <div>
              <h5>{survey?.questions[0].question}</h5>
              <p className="text-faded small mt8">{survey?.questions[0].description}</p>
            </div>
            <div>
              <Button className="icon-btn m0 mt-10 mr-10" onClick={dismissSurvey}>
                <Icon>close</Icon>
              </Button>
            </div>
          </div>
          {survey?.questions.map((question, index) => {
            if (question.type === 'rating') {
              return (
                <React.Fragment key={index}>
                  <EmojiRatingInput
                    lowerBoundLabel={question.lowerBoundLabel}
                    upperBoundLabel={question.upperBoundLabel}
                    output={(value) => {
                      setChosenOption(value);
                    }}
                  />
                </React.Fragment>
              );
            }
            if (question.type === 'open') {
              return (
                <div key={index} className="mt20">
                  <p className="small text-faded">{question.question}</p>
                  <textarea
                    className="mt8"
                    placeholder={question.description}
                    value={freeformText}
                    onChange={(e) => {
                      setFreeformText(e.target.value);
                    }}
                  />
                </div>
              );
            }
            return null;
          })}

          <div className="pos-rel"></div>
          <Button className="btn-white w100p ml0 mt20" onClick={sendSurveyFeedback}>
            {t('COMMON.SUBMIT')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CSATSurvey;
