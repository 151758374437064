import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon, MenuItem, Select } from '@mui/material';

import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { OnboardingSteps, PromoOptions } from '@/models/Enums';
import { ArtistLatestReleaseModel, LatestReleaseTrackModel } from '@/models/Spotify';
import OnboardingAPI from '@/network/OnboardingAPI';
import { dateTimeFormatter } from '@/utility/date';

import Loading from '../utility/Loading';
import Card from '../utility/microcomponents/Card';
import CampaignStatus from '../utility/statuses/CampaignStatus';

const ChooseTrack = () => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();

  const [latestRelease, setLatestRelease] = useState<ArtistLatestReleaseModel>();
  const [latestReleaseLoading, setLatestReleaseLoading] = useState<boolean>(true);
  const [chosenOption, setChosenOption] = useState<PromoOptions>(PromoOptions.BOOST);
  const [selectedRelease, setSelectedRelease] = useState<LatestReleaseTrackModel>();

  const { setOnboardingStep, onboardingStep } = useOnboardingContext();

  const { watch, setValue } = useFormContext();

  const artistId = watch('artistId');

  const getArtistLatestRelease = useCallback(() => {
    OnboardingAPI.getArtistLatestRelease({ spotifyArtistId: artistId })
      .then((response) => {
        setLatestRelease(response.data);
        setLatestReleaseLoading(false);
      })
      .catch(() => {
        setLatestReleaseLoading(false);
      });
  }, [artistId]);

  useEffect(() => {
    if (!artistId) return setLatestReleaseLoading(false);
    getArtistLatestRelease();
  }, [getArtistLatestRelease, artistId]);

  useEffect(() => {
    if (latestRelease && latestRelease.tracks.length > 1) {
      setSelectedRelease(latestRelease.tracks.find((option) => option.name === latestRelease.mostPopularTrack.name));
    }
  }, [latestRelease]);

  useEffect(() => {
    if (selectedRelease && latestRelease) {
      setValue('releaseDetails.releaseSpotifyId', selectedRelease.spotifyId);
      setValue('releaseDetails.releaseTitle', selectedRelease.name);
      setValue('releaseDetails.releaseArtwork', latestRelease.image.url);
      setValue('releaseDetails.releaseDate', latestRelease.releaseDate);
    }
  }, [latestRelease, selectedRelease, setValue]);

  useEffect(() => {
    setValue('releaseChoice', chosenOption);
    if (chosenOption === PromoOptions.NEW) {
      setValue('releaseDetails.releaseSpotifyId', '');
      setValue('releaseDetails.releaseTitle', '');
      setValue('releaseDetails.releaseArtwork', '');
      setValue('releaseDetails.releaseDate', '');
    }
  }, [chosenOption, setValue]);

  return (
    <>
      {breakpointHit && (
        <div className="text-left ml-16">
          <Button
            className="no-bg text-lef m0 pl0"
            onClick={() => {
              setOnboardingStep(onboardingStep - 1);
            }}
          >
            <Icon>chevron_left</Icon>
            {t('COMMON.BACK')}
          </Button>
        </div>
      )}
      <h2 className={`text-center ${breakpointHit ? 'mt10' : 'mt40'} mb48 max-w500 mr-auto ml-auto`}>
        {t('ONBOARDING.DO-YOU-HAVE-MUSIC-TO-PROMOTE')}
      </h2>

      {latestReleaseLoading && <Loading />}
      {!latestReleaseLoading && (
        <>
          {latestRelease && (
            <div
              data-testid="release-choice-boost"
              className={`max-w400 ml-auto mr-auto ${chosenOption === PromoOptions.BOOST ? 'to-do-card-border' : 'to-do-card-pre-border'}`}
            >
              <div
                className="card artist-onboarding-card cursor-pointer"
                onClick={() => {
                  setChosenOption(PromoOptions.BOOST);
                }}
              >
                <div
                  className="pos-abs"
                  style={{
                    backgroundImage: `url(${latestRelease?.image?.url})`,
                    borderRadius: '12px 12px 12px 12px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: '-50%',
                    height: '180px',
                    width: '100%',
                    maxWidth: '400px',
                  }}
                />
                <div className="inner-titles max-w400p">
                  <div className="d-flex">
                    <div className="text-left">
                      <h5>{t('ONBOARDING.BOOST-YOUR-LAST-RELEASE')}</h5>
                      <p className="text-reduced-opacity small">
                        {t('ONBOARDING.BOOST-YOUR-LAST-RELEASE-DESCRIPTION')}
                      </p>
                    </div>
                    <div className="ml-auto mb-auto">
                      {chosenOption === PromoOptions.BOOST ? (
                        <div>
                          <Icon className="text-blue">check_circle</Icon>
                        </div>
                      ) : (
                        <div>
                          <Icon className="material-symbols-outlined text-faded">circle</Icon>
                        </div>
                      )}
                    </div>
                  </div>
                  {latestRelease && (
                    <div className="d-flex inner-titles p8 mt20">
                      <img src={latestRelease?.image?.url} className="br50p" alt="" height={50} />
                      <div className="p8 text-left">
                        <CampaignStatus status="post-release" />
                        <h5 className="mt4">{selectedRelease?.name}</h5>
                        <p className="text-faded mt2 small">
                          {t('ONBOARDING.RELEASED')} {dateTimeFormatter(latestRelease?.releaseDate, 'dateTimeAgo')}
                        </p>
                      </div>
                    </div>
                  )}
                  {latestRelease && latestRelease.tracks.length > 1 && selectedRelease && (
                    <>
                      <p className="mt10 text-left">{t('ONBOARDING.SELECT-A-TRACK')}</p>
                      <Select
                        id="release-cycle-selector"
                        className="mt8"
                        value={selectedRelease.name}
                        defaultValue={
                          latestRelease.tracks.find((option) => option.name === latestRelease.mostPopularTrack.name)
                            ?.name
                        }
                        onChange={(e) => {
                          const selected = latestRelease.tracks.find((option) => option.name === e.target.value);
                          selected && setSelectedRelease(selected);
                        }}
                      >
                        {latestRelease.tracks.map((release) => (
                          <MenuItem key={release.spotifyId} value={release.name}>
                            {release.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            data-testid="release-choice-promote"
            className={`max-w400 ml-auto mr-auto mt20 ${chosenOption === PromoOptions.NEW ? 'to-do-card-border' : 'to-do-card-pre-border'}`}
          >
            <Card
              className="text-left d-flex cursor-pointer gap8"
              onClick={() => {
                setChosenOption(PromoOptions.NEW);
              }}
            >
              <div>
                <h5>{t('ONBOARDING.PROMOTE-A-NEW-TRACK')}</h5>
                <p className="text-faded small">{t('ONBOARDING.PROMOTE-A-NEW-TRACK-DESCRIPTION')}</p>
              </div>
              <div className="ml-auto mb-auto">
                {chosenOption === PromoOptions.NEW ? (
                  <div>
                    <Icon className="text-blue">check_circle</Icon>
                  </div>
                ) : (
                  <div>
                    <Icon className="material-symbols-outlined text-faded">circle</Icon>
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div
            data-testid="release-choice-none"
            className={`max-w400 ml-auto mr-auto mb150 mt20 ${chosenOption === PromoOptions.NONE ? 'to-do-card-border' : 'to-do-card-pre-border'}`}
          >
            <Card
              className="text-left d-flex cursor-pointer gap8"
              onClick={() => {
                setChosenOption(PromoOptions.NONE);
              }}
            >
              <div>
                <h5 className="mt-auto mb-auto">{t('ONBOARDING.NOTHING-TO-PROMOTE-NOW')}</h5>
                <p className="text-faded small">{t('ONBOARDING.NOTHING-TO-PROMOTE-NOW-DESCRIPTION')}</p>
              </div>
              <div className="ml-auto mt-auto mb-auto">
                {chosenOption === PromoOptions.NONE ? (
                  <div>
                    <Icon className="text-blue">check_circle</Icon>
                  </div>
                ) : (
                  <div>
                    <Icon className="material-symbols-outlined text-faded">circle</Icon>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </>
      )}

      <div className="onboarding-footer">
        <div className="d-flex jc-end mt40">
          {!breakpointHit && (
            <Button
              className="border-btn min-w130"
              onClick={() => {
                setOnboardingStep(onboardingStep - 1);
              }}
            >
              {t('COMMON.BACK')}
            </Button>
          )}
          <Button
            className={`btn-white min-w130 ${breakpointHit ? 'w100p' : ''}`}
            disabled={chosenOption === undefined}
            data-testid="continue-button"
            onClick={() => {
              if (
                (chosenOption === PromoOptions.NONE || chosenOption === PromoOptions.BOOST) &&
                location.pathname === '/onboarding'
              ) {
                return setOnboardingStep(OnboardingSteps.CREATE_ACCOUNT);
              }
              if (chosenOption === PromoOptions.NONE || chosenOption === PromoOptions.BOOST) {
                return setOnboardingStep(OnboardingSteps.SIGN_UP);
              }
              setOnboardingStep(OnboardingSteps.RELEASE_DETAILS);
            }}
          >
            {t('COMMON.CONTINUE')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChooseTrack;
