import React, { CSSProperties, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress } from '@mui/material';

import { Images } from '@/constants/Images';
import useAccountContext from '@/hooks/context/useAccountContext';
import { ScheduleAdviceModel } from '@/models/ReleaseCycles';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';

import Card from '../utility/microcomponents/Card';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import TaskPill from '../utility/statuses/TaskPill';

export const ScheduleAdviceCard = ({
  advice,
  date,
  index,
  isWeek,
  isWeekMobile,
  isCard,
  hideImage,
  updateList,
}: {
  advice: ScheduleAdviceModel;
  date: string;
  index?: number;
  isWeek?: boolean;
  isWeekMobile?: boolean;
  isCard?: boolean;
  hideImage?: boolean;
  updateList: (id: string) => void;
}) => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const markAsRead = useCallback(async () => {
    setIsLoading(true);
    if (!accountId) return;
    ReleaseCyclesAPI.markAdviceAsRead({
      scheduleId: advice.releaseCycleId,
      adviceId: advice.id,
      date,
      data: { accountId: accountId, isRead: !advice.isRead },
    })
      .then(() => {
        advice.isRead = !advice.isRead;
        updateList(advice.id);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [accountId, advice, date, updateList]);

  if (isWeek) {
    return (
      <div className={`to-do-card-border mb8 ${advice.isRead ? 'reduce-advice-opacity' : ''}`}>
        <Card
          innerInner
          key={index}
          className="p0 text-left to-do-card"
          style={{ '--animation-number': `${index}` } as CSSProperties}
        >
          <div className="p10">
            <TaskPill topTip />

            <p className={`${advice.isRead ? 'text-faded strikethrough' : ''} mt8`}>{advice.title}</p>
            {!advice.isRead && (
              <p className={`${advice.isRead ? 'text-faded strikethrough' : ''} mt8 small text-faded`}>{advice.body}</p>
            )}
            {!advice.isRead && (
              <Button className="btn-grey w100p m0 mt10" onClick={markAsRead} disabled={isLoading}>
                {isLoading ? <CircularProgress size={16} /> : t('COMMON.MARK-AS-READ')}
              </Button>
            )}
            {advice.isRead && (
              <Button className="btn-grey w100p m0 mt10" onClick={markAsRead} disabled={isLoading}>
                {isLoading ? <CircularProgress size={16} /> : t('COMMON.MARK-AS-UNREAD')}
              </Button>
            )}
          </div>
        </Card>
      </div>
    );
  }

  if (isWeekMobile) {
    return (
      <div className={`to-do-card-border mb8 ${advice.isRead ? 'reduce-advice-opacity' : ''}`}>
        <Card
          innerInner
          key={index}
          className="p0 text-left to-do-card"
          style={{ '--animation-number': `${index}` } as CSSProperties}
        >
          {!advice.isRead && !hideImage && (
            <img src={Images.advice} alt={advice.title} width={'100%'} className="br8 object-fit-cover" />
          )}
          <div className="p10">
            <div className="">
              <h5 className={`d-flex ${advice.isRead ? 'text-faded strikethrough' : ''}`}>
                <CustomIcons name="lightBulb" className={`pr8 mt1 ${advice.isRead ? 'text-faded' : ''}`} />
                {advice.title}
              </h5>
              <p className={`text-faded small ${advice.isRead ? 'strikethrough' : ''}`}>{advice.body}</p>
              <div className="w100p mt10">
                <Button className="btn-grey w100p m0" onClick={markAsRead} disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress size={16} />
                  ) : advice.isRead ? (
                    t('RELEASE-CYCLES.MARK-AS-UNREAD')
                  ) : (
                    t('COMMON.MARK-AS-READ')
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  if (isCard) {
    return (
      <div className="to-do-card-border">
        <Card
          innerInner
          key={index}
          className="p0 text-left min-w300 max-w300 min-h300 pos-rel to-do-card"
          style={
            {
              '--animation-number': `${index}`,
            } as CSSProperties
          }
        >
          <div
            className="pos-abs b0"
            style={
              {
                height: '100%',
                backgroundImage: `url(${Images.releaseCycles.waves})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: 'bottom',
              } as CSSProperties
            }
          >
            <div className="h100p">
              <div className="p10">
                <TaskPill topTip />
                <p className={`${advice.isRead ? 'text-faded strikethrough' : ''} mt8`}>{advice.title}</p>
                <p className={`text-faded small ${advice.isRead ? 'strikethrough' : ''}`}>{advice.body}</p>
              </div>
            </div>
            <div className="pos-abs w100p b0 l0">
              <div className="p10">
                <Button className="btn-grey w100p m0" onClick={markAsRead} disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress size={16} />
                  ) : advice.isRead ? (
                    t('RELEASE-CYCLES.MARK-AS-UNREAD')
                  ) : (
                    t('COMMON.MARK-AS-READ')
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
  return (
    <div className={`to-do-card-border ${advice.isRead ? 'reduce-advice-opacity' : ''}`}>
      <Card
        key={index}
        innerInner
        className={`d-flex p10 pos-rel to-do-card transition-all dynamic-card-transition ${advice.isRead ? 'collapsed' : 'expanded'}`}
        style={{ '--animation-number': `${index}` } as React.CSSProperties}
      >
        <img src={Images.advice} alt={advice.title} className="br8 object-fit-cover" />
        <div className="ml20 pos-rel flex-grow">
          <div className="to-reduce">
            <TaskPill topTip />
            <div className="d-flex jc-space-between mt10">
              <div>
                <h5 className={`${advice.isRead ? 'text-faded strikethrough' : ''}`}>{advice.title}</h5>
              </div>
            </div>
            {!advice.isRead && <p className="text-faded mt10 mb90">{advice.body}</p>}
          </div>
          <div className="b0 l0 d-flex pos-abs w100p flex-wrap gap8 jc-end">
            <Button className="btn-grey min-w200 m0" onClick={markAsRead} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={16} />
              ) : advice.isRead ? (
                t('RELEASE-CYCLES.MARK-AS-UNREAD')
              ) : (
                t('COMMON.MARK-AS-READ')
              )}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};
