import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Icon, IconButton, InputAdornment, Menu, MenuItem, Modal, OutlinedInput, Select } from '@mui/material';

import useArtist from '@/hooks/artist/useArtist';
import useReleaseCycles from '@/hooks/release-cycles/useReleaseCycles';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { ReleaseCycleModel } from '@/models/ReleaseCycles';

import ButtonComponent from '../utility/microcomponents/Button';
import Card from '../utility/microcomponents/Card';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import MenuModal from '../utility/navigation/MenuModal';
import { ReleaseCycleButton } from './ReleaseCycleFlow';

const ReleaseCyclePageHeader = ({
  showCompleted,
  viewCompleted,
  selectedReleaseCycle,
}: {
  title?: string;
  isReleaseCycle?: boolean;
  showCompleted?: boolean;
  viewCompleted?: (showCompleted: boolean) => void;
  selectedReleaseCycle?: (releaseCycle: ReleaseCycleModel) => void;
}) => {
  const navigate = useNavigate();
  const { artist } = useArtist();
  const [params, setSearchParams] = useSearchParams();
  const { breakpointHit } = useBreakpoints();
  const { t } = useTranslation();
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const { releaseCycles, refetchReleaseCycles } = useReleaseCycles({});

  const [selectedRelease, setSelectedRelease] = useState<ReleaseCycleModel>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (releaseCycles && releaseCycles.length > 0) {
      const hasAllTasks = releaseCycles.find((release) => release.name === 'All tasks');
      if (!hasAllTasks && releaseCycles.length > 1) {
        releaseCycles.push({ id: '', name: 'All tasks' } as ReleaseCycleModel);
      }
      if (params.get('id') && params.get('id') !== '') {
        const selected = releaseCycles.find((option) => option.id === params.get('id'));
        selected && setSelectedRelease(selected);
      } else if (params.get('id') === '') {
        setSelectedRelease({ id: '', name: 'All tasks' } as ReleaseCycleModel);
      } else {
        setSelectedRelease(releaseCycles[0]);
      }
    }
    return () => {
      if (releaseCycles && releaseCycles.length === 3) {
        releaseCycles.pop();
      }
    };
  }, [params, releaseCycles]);

  useEffect(() => {
    if (selectedRelease && selectedReleaseCycle) {
      params.set('id', selectedRelease.id);
      setSearchParams(params);
      selectedReleaseCycle(selectedRelease);
    }
  }, [params, selectedRelease, selectedReleaseCycle, setSearchParams]);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <>
          <MenuModal closeModalOutput={() => setOpen(false)} />
        </>
      </Modal>
      <div
        className={`${params.get('view') === 'day' ? '' : `bg-bg pos-sticky z-1000 ${breakpointHit ? 't10' : 't20'}`}`}
      >
        <Card inner className="p16 pb0">
          <div className="d-flex jc-space-between">
            {(params.get('view') === 'settings' || params.get('view') === 'previous') && (
              <Button
                className="ml0 btn-back"
                onClick={() => {
                  params.set('view', 'day');
                  setSearchParams(params);
                }}
              >
                <Icon>chevron_left</Icon>
                <p className="pt2 pl4">{t('COMMON.BACK')}</p>
              </Button>
            )}
            {releaseCycles &&
              releaseCycles.length > 1 &&
              selectedRelease &&
              (params.get('view') === 'day' || params.get('view') === 'week') && (
                <Select
                  id="release-cycle-selector"
                  className="release-cycle-selector"
                  value={selectedRelease?.name}
                  open={isSelectOpen}
                  onClick={() => setIsSelectOpen(!isSelectOpen)}
                  onClose={() => setIsSelectOpen(false)}
                  defaultValue={releaseCycles[0].name}
                  sx={{
                    width: 'fit-content !important',
                    // minWidth: '200px !important',
                  }}
                  input={
                    <OutlinedInput
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={() => setIsSelectOpen(true)} edge="end">
                            <Icon className="material-symbols-outlined">expand_more</Icon>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  }
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setSelectedRelease({ id: '', name: 'All tasks' } as ReleaseCycleModel);
                      return;
                    }
                    const selected = releaseCycles.find((option) => option.name === e.target.value);
                    selected && setSelectedRelease(selected);
                  }}
                >
                  {releaseCycles.map((release, index) => (
                    <MenuItem
                      key={release.id}
                      value={release.name}
                      className={index === releaseCycles.length - 1 ? 'no-border' : ''}
                    >
                      {release.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            {releaseCycles &&
              releaseCycles.length === 1 &&
              selectedRelease &&
              (params.get('view') === 'day' || params.get('view') === 'week') && (
                <h3 id="release-cycle-selector" className="mb0">
                  {selectedRelease.name}
                </h3>
              )}
            <div className="d-flex ml-auto">
              {!breakpointHit && (
                <>
                  {(params.get('view') === 'day' || params.get('view') === 'week') && (
                    <Button
                      id="release-cycles-switch-view-button"
                      className="m0 d-flex min-w200 icon-prefix material-icons no-bg"
                      onClick={() => {
                        if (params.get('view') === 'week') {
                          params.set('view', 'day');
                        } else {
                          params.set('view', 'week');
                        }
                        setSearchParams(params);
                      }}
                    >
                      <span>
                        {params.get('view') === 'day' ? (
                          <CustomIcons name="calendar" className="text-white calendar mt4"></CustomIcons>
                        ) : (
                          <CustomIcons name="card" className="mt4 text-white"></CustomIcons>
                        )}
                      </span>

                      <span className="mt-auto mb-auto pl8">
                        {params.get('view') === 'day'
                          ? t('RELEASE-CYCLES.SWITCH-TO-WEEK-VIEW')
                          : t('RELEASE-CYCLES.SWITCH-TO-DAY-VIEW')}
                      </span>
                    </Button>
                  )}
                  {(params.get('view') === 'week' || params.get('view') === 'day') && (
                    <Button
                      className="btn-black ml8 mt0 mb0 min-w200 no-bg"
                      onClick={() => {
                        viewCompleted && viewCompleted(!showCompleted);
                      }}
                    >
                      <span>
                        {showCompleted ? (
                          <CustomIcons name="visibility_off" className="mt6 fs-16"></CustomIcons>
                        ) : (
                          <CustomIcons name="visibility" className="mt6 fs-16"></CustomIcons>
                        )}
                      </span>

                      <span className="mt-auto mb-auto pl8">
                        {showCompleted
                          ? t('RELEASE-CYCLES.HIDE-COMPLETED-TASKS')
                          : t('RELEASE-CYCLES.SHOW-COMPLETED-TASKS')}
                      </span>
                    </Button>
                  )}

                  <Button
                    id="release-menu-button"
                    className="btn-black ml8 mt0 mb0 no-bg"
                    aria-controls={menuOpen ? 'release-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                    onClick={openMenu}
                  >
                    <Icon>more_horiz</Icon>
                    <span className="pl8">More</span>
                  </Button>
                  <Menu
                    id="release-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={closeMenu}
                    MenuListProps={{
                      'aria-labelledby': 'release-menu-button',
                    }}
                  >
                    <MenuItem
                      disabled={params.get('view') === 'settings'}
                      onClick={() => {
                        params.set('view', 'settings');
                        setSearchParams(params);
                        closeMenu();
                      }}
                    >
                      {t('RELEASE-CYCLES.RELEASE-SETTINGS')}
                    </MenuItem>

                    <MenuItem
                      disabled={params.get('view') === 'previous'}
                      className="no-border"
                      onClick={() => {
                        params.set('view', 'previous');
                        setSearchParams(params);
                        closeMenu();
                      }}
                    >
                      {t('RELEASE-CYCLES.PREVIOUS-RELEASES')}
                    </MenuItem>
                  </Menu>

                  {!releaseCycles ||
                    (releaseCycles?.length < 2 && (
                      <ReleaseCycleButton
                        entryType="button-add-new"
                        refetch={() => {
                          refetchReleaseCycles();
                          params.set('view', 'day');
                          setSearchParams(params);
                        }}
                      />
                    ))}
                </>
              )}

              {!breakpointHit && (
                <ButtonComponent
                  isCustom
                  onClick={() => navigate('/profile')}
                  ariaLabel="go-to-profile-page"
                  className="p0"
                >
                  <img
                    data-testid="profile-pic"
                    className={'profile-pic mt0 mb0 mr0'}
                    src={
                      artist?.details?.profileImageUrl
                        ? artist.details.profileImageUrl
                        : '/images/profile-placeholder.svg'
                    }
                    alt=""
                  />
                </ButtonComponent>
              )}
              {breakpointHit && (
                <Button className="icon-btn mt0 mb0 mr0 no-bg" data-testid="menu-button" onClick={() => setOpen(true)}>
                  <Icon className="material-symbols-outlined">menu</Icon>
                </Button>
              )}
            </div>
          </div>
          {breakpointHit && (
            <div
              className={`d-flex jc-space-between ${params.get('view') === 'day' || params.get('view') === 'week' ? 'mt10' : ''} gap10`}
            >
              {(params.get('view') === 'day' || params.get('view') === 'week') && (
                <Button
                  id="release-cycles-switch-view-button"
                  className="m0 d-flex flex-grow flex-basis icon-prefix material-icons btn-black"
                  onClick={() => {
                    if (params.get('view') === 'week') {
                      params.set('view', 'day');
                    } else {
                      params.set('view', 'week');
                    }
                    setSearchParams(params);
                  }}
                >
                  <span>
                    {params.get('view') === 'day' ? (
                      <CustomIcons name="calendar" className="text-white calendar mt4"></CustomIcons>
                    ) : (
                      <CustomIcons name="card" className="mt4"></CustomIcons>
                    )}
                  </span>

                  <span className="mt-auto mb-auto pl8">
                    {params.get('view') === 'day' ? t('RELEASE-CYCLES.WEEK-VIEW') : t('RELEASE-CYCLES.DAY-VIEW')}
                  </span>
                </Button>
              )}

              {params.get('view') !== 'previous' && params.get('view') !== 'settings' && (
                <>
                  <Button
                    id="release-menu-button"
                    className="btn-black ml8 mt0 mb0 flex-grow flex-basis"
                    aria-controls={menuOpen ? 'release-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                    onClick={openMenu}
                  >
                    <Icon>more_horiz</Icon>
                    <span className="pl8">More</span>
                  </Button>
                  <Menu
                    id="release-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={closeMenu}
                    MenuListProps={{
                      'aria-labelledby': 'release-menu-button',
                    }}
                  >
                    <MenuItem
                      disabled={params.get('view') === 'settings'}
                      onClick={() => {
                        params.set('view', 'settings');
                        setSearchParams(params);
                        closeMenu();
                      }}
                    >
                      {t('RELEASE-CYCLES.RELEASE-SETTINGS')}
                    </MenuItem>

                    <MenuItem
                      disabled={params.get('view') === 'previous'}
                      className="no-border"
                      onClick={() => {
                        params.set('view', 'previous');
                        setSearchParams(params);
                        closeMenu();
                      }}
                    >
                      {t('RELEASE-CYCLES.PREVIOUS-RELEASES')}
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default ReleaseCyclePageHeader;
