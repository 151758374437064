import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';

import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { OnboardingSteps } from '@/models/Enums';

import { auth } from '@/firebaseSetup';

const UserStep = () => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const { setOnboardingStep, onboardingStep } = useOnboardingContext();

  const { setValue, watch } = useFormContext();
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');

  const formInvalid = useMemo(() => !(firstName !== '' && lastName !== ''), [firstName, lastName]);

  const splitDisplayName = (displayName: string) => {
    const split = displayName.split(' ');
    return {
      firstName: split[0],
      lastName: split[1],
    };
  };

  useEffect(() => {
    if (auth.currentUser && auth.currentUser.providerData[0].providerId !== 'password') {
      const { firstName, lastName } = splitDisplayName(auth.currentUser.displayName || '');
      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('email', auth.currentUser.email);
    }
  }, [setValue]);

  return (
    <>
      {breakpointHit && (
        <div className="text-left ml-16">
          <Button
            className="no-bg text-lef m0 pl0"
            onClick={() => {
              setOnboardingStep(onboardingStep - 1);
            }}
          >
            <Icon>chevron_left</Icon>
            {t('COMMON.BACK')}
          </Button>
        </div>
      )}
      <h2 className={`text-center ${breakpointHit ? 'mt10' : 'mt40'} mb48`}>{t('ONBOARDING.TELL-US-ABOUT-YOU')}</h2>
      <div className="max-w400 ml-auto mr-auto mt20">
        <form className="text-left">
          <div>
            <label>
              <p className="mt20">{t('COMMON.FIRST-NAME')} *</p>
              <input
                placeholder="John"
                name="firstName"
                value={firstName}
                data-testid="first-name-input"
                onChange={(e) => {
                  setValue('firstName', e.target.value);
                }}
              />
            </label>
            <label>
              <p className="mt20">{t('COMMON.LAST-NAME')} *</p>
              <input
                placeholder="Smith"
                name="lastName"
                value={lastName}
                data-testid="last-name-input"
                onChange={(e) => {
                  setValue('lastName', e.target.value);
                }}
              />
            </label>
            {email && (
              <label>
                <p className="mt20">{t('COMMON.EMAIL')}</p>
                <input placeholder="Email" name="email" disabled value={email} data-testid="email" />
              </label>
            )}
          </div>
        </form>
      </div>

      <div className="onboarding-footer">
        <div className="d-flex jc-end mt40">
          {!breakpointHit && (
            <Button
              className="border-btn min-w130"
              onClick={() => {
                setOnboardingStep(onboardingStep - 1);
              }}
            >
              {t('COMMON.BACK')}
            </Button>
          )}
          <Button
            className={`btn-white min-w130 ${breakpointHit ? 'w100p' : ''}`}
            disabled={formInvalid}
            data-testid="continue-button"
            onClick={() => {
              setOnboardingStep(OnboardingSteps.ARTIST);
            }}
          >
            {t('COMMON.CONTINUE')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default UserStep;
