import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import { Images } from '@/constants/Images';

import Card from '../utility/microcomponents/Card';

export const PartnerPerkWidget = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Card
      className="p10 widget-card partner-perks pos-rel text-center"
      onClick={() => {
        navigate('/partner-perks');
      }}
    >
      <img src={Images.widgetImages.partnerPerks} alt="partner-perks" height={80} className="mt10" />

      <div className="d-flex mt42 pos-abs b10 footer text-left">
        <div>
          <p className="fw-bold mt10">{t('NAVIGATION.PARTNER-PERKS')}</p>
        </div>
        <Button className="widget-button">
          <Icon>chevron_right</Icon>
        </Button>
      </div>
    </Card>
  );
};
