import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, CircularProgress } from '@mui/material';

import useAccountContext from '@/hooks/context/useAccountContext';
import useReleaseCycles from '@/hooks/release-cycles/useReleaseCycles';
import useReleaseCycleTaskCompletionStats from '@/hooks/release-cycles/useReleaseCycleTaskCompletionStats';
import { ReleaseCycleModel } from '@/models/ReleaseCycles';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';

import Card from '../utility/microcomponents/Card';
import DialogModal from '../utility/modals/DialogModal';

export const ReleaseSettings = ({ release }: { release: ReleaseCycleModel }) => {
  const { accountId } = useAccountContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isEndOpen, setIsEndOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [chosenRelease, setChosenRelease] = useState<ReleaseCycleModel>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { releaseCycles, refetchReleaseCycles } = useReleaseCycles({});
  const { refetchReleaseCyclesCompletionStats } = useReleaseCycleTaskCompletionStats({ scheduleId: '' });
  const { refetchReleaseCycles: refetchPreviousReleases } = useReleaseCycles({ isCompleted: true });

  const completeRelease = useCallback(
    async (id: string) => {
      if (!release || !accountId) return;
      setIsLoading(true);
      ReleaseCyclesAPI.completeReleaseCycle({ scheduleId: id, accountId: accountId })
        .then(async () => {
          await refetchReleaseCycles();
          await refetchReleaseCyclesCompletionStats();
          await refetchPreviousReleases();
          navigate('/release-cycles?view=day&showReleaseCycleEndSurvey=true');
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [accountId, navigate, refetchPreviousReleases, refetchReleaseCycles, refetchReleaseCyclesCompletionStats, release]
  );

  const deleteReleaseCycle = useCallback(
    (id: string) => {
      if (!release || !accountId) return;
      setIsLoading(true);
      ReleaseCyclesAPI.deleteReleaseCycle({ accountId: accountId, scheduleId: id })
        .then(async () => {
          await refetchReleaseCycles();
          await refetchReleaseCyclesCompletionStats();
          await refetchPreviousReleases();
          navigate('/release-cycles?view=day');
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [accountId, navigate, refetchPreviousReleases, refetchReleaseCycles, refetchReleaseCyclesCompletionStats, release]
  );

  return (
    <>
      <DialogModal
        title={t('RELEASE-CYCLES.DELETE-RELEASE-CYCLE')}
        content={t('RELEASE-CYCLES.DELETE-RELEASE-CYCLE-CONFIRMATION')}
        open={isDeleteOpen}
        output={(output) => {
          if (output && chosenRelease) {
            deleteReleaseCycle(chosenRelease?.id);
          }
          setIsDeleteOpen(false);
        }}
        isDeleteReleaseCycle
      />
      <DialogModal
        title={t('RELEASE-CYCLES.END-RELEASE-CYCLE')}
        content={t('RELEASE-CYCLES.END-RELEASE-CYCLE-CONFIRMATION')}
        open={isEndOpen}
        output={(output) => {
          if (output && chosenRelease) {
            completeRelease(chosenRelease?.id);
          }
          setIsEndOpen(false);
        }}
        isEndReleaseCycle
      />

      <div className="">
        <h3 className="mt20">{t('RELEASE-CYCLES.RELEASE-SETTINGS')}</h3>
        <div className="d-flex gap20 flex-wrap">
          {release.id === '' &&
            releaseCycles &&
            releaseCycles.map((release: ReleaseCycleModel) => {
              if (release.id === '') {
                return null;
              }
              return (
                <Card key={release.id} inner className="p10 mt20 max-w400">
                  <h4 className="">{release.name}</h4>
                  <h5 className="mt20">{t('RELEASE-CYCLES.END-RELEASE')}</h5>
                  <p className="text-faded mt8">{t('RELEASE-CYCLES.END-RELEASE-DESCRIPTION')}</p>
                  <Button
                    className="btn-white ml0 mt8 w100p"
                    onClick={() => {
                      setChosenRelease(release);
                      setIsEndOpen(true);
                    }}
                  >
                    {isLoading ? <CircularProgress size={16} /> : t('RELEASE-CYCLES.END-RELEASE-CYCLE')}
                  </Button>
                  <h5 className="mt20">{t('RELEASE-CYCLES.DELETE-RELEASE')}</h5>
                  <p className="text-faded mt8">{t('RELEASE-CYCLES.DELETE-RELEASE-DESCRIPTION')}</p>

                  <Button
                    className="btn-black ml0 mt8 w100p"
                    onClick={() => {
                      setChosenRelease(release);
                      setIsDeleteOpen(true);
                    }}
                  >
                    <p className="text-error small pt0">
                      {isLoading ? <CircularProgress size={16} /> : t('RELEASE-CYCLES.DELETE-RELEASE-CYCLE')}
                    </p>
                  </Button>
                </Card>
              );
            })}
        </div>
        {release.id !== '' && (
          <Card key={release.id} inner className="p10 mt20 max-w400">
            <h4 className="">{release.name}</h4>
            <h5 className="mt20">{t('RELEASE-CYCLES.END-RELEASE')}</h5>
            <p className="text-faded mt8">{t('RELEASE-CYCLES.END-RELEASE-DESCRIPTION')}</p>
            <Button
              className="btn-white ml0 mt8 w100p"
              onClick={() => {
                setChosenRelease(release);
                setIsEndOpen(true);
              }}
            >
              {isLoading ? <CircularProgress size={16} /> : t('RELEASE-CYCLES.END-RELEASE-CYCLE')}
            </Button>
            <h5 className="mt20">{t('RELEASE-CYCLES.DELETE-RELEASE')}</h5>
            <p className="text-faded mt8">{t('RELEASE-CYCLES.DELETE-RELEASE-DESCRIPTION')}</p>

            <Button
              className="btn-black ml0 mt8 w100p"
              onClick={() => {
                setChosenRelease(release);
                setIsDeleteOpen(true);
              }}
            >
              <p className="text-error small pt0">
                {isLoading ? <CircularProgress size={16} /> : t('RELEASE-CYCLES.DELETE-RELEASE-CYCLE')}
              </p>
            </Button>
          </Card>
        )}
      </div>
    </>
  );
};
