import React from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { Images } from '@/constants/Images';
import useReleaseCycles from '@/hooks/release-cycles/useReleaseCycles';
import { ReleaseCycleModel } from '@/models/ReleaseCycles';

import Loading from '../utility/Loading';
import Card from '../utility/microcomponents/Card';
import ProgressBar from '../utility/ProgressBar';

export const PreviousReleases = () => {
  const { t } = useTranslation();
  const { releaseCycles, releaseCyclesIsLoading } = useReleaseCycles({ isCompleted: true });

  if (!releaseCyclesIsLoading && releaseCycles?.length === 0) {
    return (
      <>
        <p className="small">{t('RELEASE-CYCLES.NO-PREVIOUS-RELEASES')}</p>
      </>
    );
  }
  return (
    <Card inner className="p10">
      {releaseCyclesIsLoading && (
        <div className="centered-loading">
          <Loading size="small" />
        </div>
      )}
      {!releaseCyclesIsLoading &&
        releaseCycles?.map((release: ReleaseCycleModel, index) => (
          <div key={release.id}>
            <div className={`d-flex gap10`}>
              <object
                className="br50p object-fit-cover mt-auto mb-auto"
                data={release.imageUrl || Images.releaseCycles.defaultReleaseArtwork}
                type="image/png"
                height={66}
                width={66}
              >
                <img
                  src={Images.unhurdFallback}
                  alt={release.name}
                  height={66}
                  width={66}
                  className="br50p object-fit-cover"
                />
              </object>
              <div className="flex-grow">
                <h5>{release.name}</h5>
                <p className="small text-faded pt0">
                  • {t('RELEASE-CYCLES.RELEASE-CYCLE-ENDED')} {format(new Date(release.completedDate), 'do LLLL yyyy')}
                </p>
                <p className="text-faded small mb-6 mt4">
                  {release.completionStats?.completedTasks}/{release.completionStats?.totalTasks}{' '}
                  {t('RELEASE-CYCLES.TASKS-COMPLETED')}
                </p>
                {release.completionStats && (
                  <ProgressBar
                    progress={(release.completionStats?.completedTasks / release.completionStats?.totalTasks) * 100}
                  />
                )}
              </div>
            </div>
            {index < releaseCycles.length - 1 && (
              <div className="d-flex form-divider mb10">
                <div className="line"></div>
              </div>
            )}
          </div>
        ))}
    </Card>
  );
};
