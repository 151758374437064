import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios, { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';

import { PromoteFlowStepFormatter } from '@/formatters/PromoteFlowStepFormatter';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import { AccountModel, CreateAccountModel } from '@/models/Account';
import { PromoOptions } from '@/models/Enums';
import { MetaErrorModel } from '@/models/Meta';
import { OnboardingForm } from '@/models/Onboarding';
import AccountAPI from '@/network/AccountAPI';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';
import UserTracking from '@/services/UserTracking';

import Loading from '../utility/Loading';

import { auth } from '@/firebaseSetup';

const CreateAccountPage = ({ formDetails }: { formDetails: OnboardingForm }) => {
  const { dispatchFirebaseAccountId } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();
  const userTracking = UserTracking;
  const hasRunCreateAccountRef = useRef(false);
  const hasRunCreateReleaseRef = useRef(false);

  const [hasCreatedRelease, setHasCreatedRelease] = useState<boolean>(false);
  const [hasTriedAccountCreation, setHasTriedAccountCreation] = useState<boolean>(false);

  const navigate = useNavigate();

  const updateAccount = useCallback(
    async (accountId: string) => {
      const details = formDetails;
      if (!details.artistId || !accountId) return;
      const data = {
        spotifyArtistId: details.artistId,
      };
      try {
        await AccountAPI.updateAccount({ accountId: accountId, data });
      } catch {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: 'Oops! Something went wrong. Please try again.',
            type: 'error',
          },
        });
      }
    },
    [dispatchSnackbar, formDetails]
  );

  const createRelease = useCallback(
    async (accountId: string) => {
      if (hasCreatedRelease || hasRunCreateReleaseRef.current) return;

      hasRunCreateReleaseRef.current = true;
      if (
        !accountId ||
        !formDetails.releaseDetails.releaseTitle ||
        !formDetails.releaseDetails.releaseDate ||
        formDetails.releaseChoice === PromoOptions.NONE ||
        hasCreatedRelease
      )
        return;
      await ReleaseCyclesAPI.createReleaseCycle({
        data: {
          accountId: accountId,
          name: formDetails.releaseDetails.releaseTitle,
          releaseDate: format(new Date(formDetails.releaseDetails.releaseDate), 'yyyy-MM-dd'),
          ...(formDetails.releaseDetails.releaseArtwork !== '' && {
            imageUrl: formDetails.releaseDetails.releaseArtwork,
          }),
          ...(formDetails.releaseDetails.releaseSpotifyId !== '' && {
            spotifyId: formDetails.releaseDetails.releaseSpotifyId,
          }),
        },
      })
        .then(() => {
          return setHasCreatedRelease(true);
        })
        .catch(() => {
          dispatchSnackbar({
            type: 'OPEN_SNACKBAR',
            payload: {
              message: 'We could not create your release. Please try again',
              type: 'error',
            },
          });
          return;
        });
    },
    [
      dispatchSnackbar,
      formDetails.releaseChoice,
      formDetails.releaseDetails.releaseArtwork,
      formDetails.releaseDetails.releaseDate,
      formDetails.releaseDetails.releaseSpotifyId,
      formDetails.releaseDetails.releaseTitle,
      hasCreatedRelease,
    ]
  );

  const formDetailsValid = formDetails.firstName !== '' && formDetails.lastName !== '';

  const createAccount = useCallback(async () => {
    if (hasTriedAccountCreation || hasRunCreateAccountRef.current) return;

    hasRunCreateAccountRef.current = true;

    const details = formDetails;
    if (!formDetailsValid || hasTriedAccountCreation) return;

    const data: CreateAccountModel = {
      userId: auth.currentUser?.uid,
      contact: {
        firstName: details.firstName,
        lastName: details.lastName,
        email: auth.currentUser?.email || details.email,
      },
      artistId: details.artistId,
      countryCode: details.country || 'GB',
      marketingAccepted: details.marketing,
    };
    try {
      await AccountAPI.getAccount({ firebaseAccountId: auth.currentUser?.uid })
        .then(async (resp: AxiosResponse<AccountModel>) => {
          axios.defaults.headers.common['X-Account-Id'] = resp.data.id;
          await createRelease(resp.data.id);
          await updateAccount(resp.data.id);
          await dispatchFirebaseAccountId(auth.currentUser?.uid || '');
          localStorage.removeItem('onboardingForm');
          setHasTriedAccountCreation(true);
          navigate('/');
        })
        .catch(async () => {
          await AccountAPI.createAccount({ data })
            .then(async (resp: AxiosResponse<AccountModel>) => {
              axios.defaults.headers.common['X-Account-Id'] = resp.data.id;
              userTracking?.customStaticMixpanelEvent(
                `Signed up with release choice: ${PromoteFlowStepFormatter('promote-choice', details.releaseChoice)}`,
                {}
              );
              await createRelease(resp.data.id);
              await updateAccount(resp.data.id);
              await dispatchFirebaseAccountId(resp.data.userId);
              localStorage.removeItem('onboardingForm');
              const tracking = new UserTracking({ account: resp.data });
              tracking.userSignUp();
              setHasTriedAccountCreation(true);
              navigate('/');
            })
            .catch((error: AxiosError<MetaErrorModel>) => {
              if (error.response?.data?.errorMessage === 'Account already exists with the specified identifier.') {
                return;
              } else {
                dispatchSnackbar({
                  type: 'OPEN_SNACKBAR',
                  payload: {
                    message: 'Oops! Something went wrong. Please try again.',
                    type: 'error',
                  },
                });
              }
            });
        });
    } catch {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: 'Oops! Something went wrong. Please try again.',
          type: 'error',
        },
      });
    }
    setHasTriedAccountCreation(true);
  }, [
    createRelease,
    dispatchFirebaseAccountId,
    dispatchSnackbar,
    formDetails,
    formDetailsValid,
    hasTriedAccountCreation,
    navigate,
    updateAccount,
    userTracking,
  ]);

  useEffect(() => {
    createAccount();
  }, [createAccount]);

  return (
    <>
      <div className="centered-loading-main h100p">
        <Loading />
      </div>
    </>
  );
};

export default CreateAccountPage;
