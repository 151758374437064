import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import { A11y, Autoplay, Navigation, Pagination, Parallax, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Images } from '@/constants/Images';
import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { OnboardingSteps } from '@/models/Enums';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import 'swiper/css';

const OnboardingLandingPage = () => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const { setOnboardingStep } = useOnboardingContext();

  const slideData = [
    {
      title: 'Your next fan, just a click away',
      isVideo: true,
      videoUrl: breakpointHit ? Images.onboarding.onboardingGraphicMobile : Images.onboarding.onboardingGraphic,
    },
    {
      title: 'Join more than 120k artists who have trusted us to grow their fanbase',
      image: Images.onboarding.reviewCards,
    },
    {
      title: 'Your next fan, just a click away',
      isVideo: true,
      videoUrl: breakpointHit ? Images.onboarding.onboardingGraphicMobile : Images.onboarding.onboardingGraphic,
    },
    {
      title: 'Join more than 120k artists who have trusted us to grow their fanbase',
      image: Images.onboarding.reviewCards,
    },
    {
      title: 'Your next fan, just a click away',
      isVideo: true,
      videoUrl: breakpointHit ? Images.onboarding.onboardingGraphicMobile : Images.onboarding.onboardingGraphic,
    },
    {
      title: 'Join more than 120k artists who have trusted us to grow their fanbase',
      image: Images.onboarding.reviewCards,
    },
  ];

  return (
    <>
      <div className={`w100p mr-auto ml-auto ${breakpointHit ? 'max-w400 mt10 mb10' : 'max-w80p mt48 mb20'}`}>
        <Swiper
          className={`br24`}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, Parallax]}
          spaceBetween={0}
          parallax={true}
          slidesPerView={1}
          navigation={false}
          centeredSlides={true}
          loop={true}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          speed={2000}
        >
          <div
            slot="container-start"
            style={
              {
                position: 'absolute',
                left: 0,
                bottom: breakpointHit ? '-150px' : '-100px',
                width: '130%',
                height: '100%',
                '-webkit-background-size': 'cover',
                backgroundPosition: 'bottom',

                backgroundSize: 'cover',
                backgroundImage: `url("${Images.onboarding.waves}")`,
              } as CSSProperties
            }
            data-swiper-parallax="-25%"
          ></div>
          {slideData.map((slide, index) => (
            <SwiperSlide key={index} className={`w100p pos-rel ${breakpointHit ? 'max-w400' : ''} overflow-hidden`}>
              <div className="p20">
                {!breakpointHit && <h2 data-swiper-parallax="-300">{slide.title}</h2>}
                {breakpointHit && <h3 data-swiper-parallax="-300">{slide.title}</h3>}
                {!slide.isVideo && (
                  <img
                    data-swiper-parallax="-100"
                    src={slide.image}
                    alt={slide.title}
                    className={`${breakpointHit ? 'w200p ml-50p' : 'w80p ml-auto mr-auto'} mt40`}
                  />
                )}
                {slide.isVideo && (
                  <video
                    data-swiper-parallax="-100"
                    className={`${breakpointHit ? 'w80p' : 'w60p'} ml-auto mr-auto mt40`}
                    autoPlay
                    muted
                    loop
                    playsInline
                    src={slide.videoUrl}
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={`${breakpointHit ? 'w100p' : 'w60p'} ml-auto mr-auto text-center`}>
        <Button
          className="btn-white w100p max-w400 m0"
          data-testid="get-started-button"
          onClick={() => {
            setOnboardingStep(OnboardingSteps.USER);
          }}
        >
          {t('PROMOTE-PAGE.GET-STARTED')}
        </Button>
        <br />
        <Button
          className="w100p max-w400 m0 mt16"
          data-testid="go-to-login-button"
          onClick={() => {
            setOnboardingStep(OnboardingSteps.LOGIN);
          }}
        >
          {t('LOGIN-PAGE.I-ALREADY-HAVE-AN-ACCOUNT')}
        </Button>
      </div>
    </>
  );
};

export default OnboardingLandingPage;
