import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, Icon, Modal } from '@mui/material';

import OnboardingProvider from '@/contexts/OnboardingContext';
import { PromoteFlowStepFormatter } from '@/formatters/PromoteFlowStepFormatter';
import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import useUserTracking from '@/hooks/useUserTracking';
import { OnboardingSteps, PromoOptions } from '@/models/Enums';
import { OnboardingForm } from '@/models/Onboarding';

import ReleaseDatePage from '../onboarding/ReleaseDate';
import ReleaseDetailsPage from '../onboarding/ReleaseDetails';
import ButtonComponent from '../utility/microcomponents/Button';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import BoostReleasedTracksPage from './BoostReleasedTrackPage';
import { DefaultReleaseCycleCard } from './DefaultReleaseCycleCard';
import ReleaseTypeChoice from './ReleaseTypeChoice';

type entryType = 'card-one' | 'card' | 'button-start-new' | 'button-add-new' | 'hidden';

export const ReleaseCycleButton = ({ entryType, refetch }: { entryType: entryType; refetch?: () => void }) => {
  const defaultValues = {
    email: '',
    firstName: '',
    lastName: '',
    country: '',
    marketing: false,
    artistId: '',
    releaseDetails: {
      releaseTitle: '',
      releaseDate: '',
      releaseType: '',
      releaseArtwork: '',
    },
  };
  const formMethods = useForm<OnboardingForm>({ defaultValues });

  return (
    <OnboardingProvider>
      <FormProvider {...formMethods}>
        <ReleaseCycleFlow entryType={entryType} refetch={refetch} />
      </FormProvider>
    </OnboardingProvider>
  );
};

const ReleaseCycleFlow = ({ entryType, refetch }: { entryType: entryType; refetch?: () => void }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const userTracking = useUserTracking();
  const { onboardingStep, setOnboardingStep } = useOnboardingContext();
  const [params, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext();

  useEffect(() => {
    setOnboardingStep(OnboardingSteps.RELEASE_TYPE_CHOICE);
  }, [setOnboardingStep]);

  const stepRef = useRef(onboardingStep);

  const trackProductViewed = useCallback(() => {
    if (!userTracking) return;
    if (stepRef.current !== onboardingStep && onboardingStep !== 0) {
      userTracking.productViewed?.({
        product: 'Release Cycle',
        productScreenName: PromoteFlowStepFormatter('release-cycle', onboardingStep),
      });
    } else if (isInitialLoad) {
      userTracking.productViewed?.({
        product: 'Release Cycle',
        productScreenName: PromoteFlowStepFormatter('release-cycle', onboardingStep),
      });
      setIsInitialLoad(false);
    }
  }, [isInitialLoad, onboardingStep, userTracking]);

  useEffect(() => {
    trackProductViewed();
  }, [trackProductViewed]);

  useEffect(() => {
    if (params.get('releaseCycleFlow') === 'true') {
      setModalOpen(true);
      setValue('releaseChoice', PromoOptions.BOOST);
    }
  }, [params, setValue]);

  const resetForm = () => {
    setValue('releaseChoice', PromoOptions.NEW);
    setValue('releaseDetails', {
      releaseTitle: '',
      releaseDate: '',
      releaseType: '',
      releaseArtwork: '',
    });
    setOnboardingStep(OnboardingSteps.RELEASE_TYPE_CHOICE);
  };

  const closeModal = () => {
    setModalOpen(false);
    resetForm();
    params.delete('releaseCycleFlow');
    setSearchParams([...params]);
  };

  return (
    <>
      {entryType === 'button-start-new' && (
        <Button className="w100p ml0 btn-white mt20" onClick={() => setModalOpen(true)}>
          {t('RELEASE-CYCLES.START-NEW-RELEASE-CYCLE')}
        </Button>
      )}
      {entryType === 'button-add-new' && (
        <Button className="btn-white ml8 mt0 mb0" onClick={() => setModalOpen(true)}>
          <span>
            <CustomIcons name="add" className="mt6"></CustomIcons>
          </span>

          <span className="mt-auto mb-auto pl8 pr8">{t('RELEASE-CYCLES.NEW')}</span>
        </Button>
      )}
      {entryType === 'card-one' && (
        <ButtonComponent isCustom className="p0 w100p" onClick={() => setModalOpen(true)}>
          <DefaultReleaseCycleCard isOne />
        </ButtonComponent>
      )}
      {entryType === 'card' && (
        <ButtonComponent isCustom className="p0 w100p" onClick={() => setModalOpen(true)}>
          <DefaultReleaseCycleCard />
        </ButtonComponent>
      )}
      {modalOpen && (
        <Modal className="p8" open={modalOpen} onClose={() => closeModal()}>
          <div className={`promote-modal-container text-center pt0 mb200`}>
            <Button className="icon-btn close-button" onClick={() => closeModal()}>
              <Icon>close</Icon>
            </Button>
            {onboardingStep === OnboardingSteps.RELEASE_TYPE_CHOICE && <ReleaseTypeChoice />}
            {onboardingStep === OnboardingSteps.BOOST_RELEASE_TRACK_PAGE && (
              <BoostReleasedTracksPage
                closeModal={(isRefetch?: boolean) => {
                  isRefetch && refetch && refetch();
                  closeModal();
                }}
              />
            )}
            {onboardingStep === OnboardingSteps.RELEASE_DETAILS && <ReleaseDetailsPage isReleaseFlow={true} />}
            {onboardingStep === OnboardingSteps.RELEASE_DATE && (
              <ReleaseDatePage
                closeModal={(isRefetch?: boolean) => {
                  isRefetch && refetch && refetch();
                  closeModal();
                }}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReleaseCycleFlow;
