import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { Images } from '@/constants/Images';
import { ReleaseCategoryNameFormatter } from '@/formatters/ReleaseCycleFormatters';
import useReleaseCycleSchedule from '@/hooks/release-cycles/useReleaseCycleSchedule';
import useUserTracking from '@/hooks/useUserTracking';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { ReleaseCycleModel, ScheduleAdviceModel, ScheduleTaskModel } from '@/models/ReleaseCycles';
import { dateTimeFormatter } from '@/utility/date';

import Card from '../utility/microcomponents/Card';
import ProgressBar from '../utility/ProgressBar';
import { BetaTag } from '../utility/statuses/BetaTag';
import ReleaseCycleStatus from '../utility/statuses/ReleaseCycleStatus';
import TaskPill from '../utility/statuses/TaskPill';
import ReleaseCycleTaskCategoryStatus from './ReleaseCycleTaskCategoryStatus';

export const ReleaseCycleCard = ({
  releaseCycle,
  minimize,
  setExpanded,
}: {
  releaseCycle: ReleaseCycleModel;
  minimize?: boolean;
  setExpanded?: (minimize: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const userTracking = useUserTracking();
  const navigate = useNavigate();
  const { releaseCycleSchedule } = useReleaseCycleSchedule({
    scheduleId: releaseCycle.id,
  });

  const [todaysSchedule, setTodaysSchedule] = useState<(ScheduleTaskModel | ScheduleAdviceModel)[]>([]);

  useEffect(() => {
    if (releaseCycleSchedule) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const year = new Date().getUTCFullYear();
      const month = new Date().getUTCMonth();
      const day = new Date().getUTCDate();
      const formattedSelectedDate = new Date(Date.UTC(year, month, day));

      const localNow = new Date(formattedSelectedDate);
      const utcDate = zonedTimeToUtc(format(localNow, 'yyyy-MM-dd'), timeZone);
      const localFormattedDate = format(utcDate, 'yyyy-MM-dd');

      const schedule = releaseCycleSchedule.find((item) => {
        const taskUTCDate = parseISO(item.date);
        const localDate = utcToZonedTime(taskUTCDate, timeZone);

        const localisedItemDate = format(localDate, 'yyyy-MM-dd');

        return localisedItemDate === localFormattedDate;
      })?.items;
      if (schedule) {
        setTodaysSchedule(schedule);
      }
    }
  }, [releaseCycleSchedule]);

  const tasksCompletedToday = todaysSchedule.filter(
    (task: ScheduleTaskModel | ScheduleAdviceModel) =>
      task.itemType === 'task' && (task as ScheduleTaskModel).isCompleted
  ).length;

  const totalTasksToday = todaysSchedule.filter(
    (task: ScheduleTaskModel | ScheduleAdviceModel) => task.itemType === 'task'
  ).length;

  const toggleNav = () => {
    setExpanded && setExpanded(!minimize);
  };

  useEffect(() => {
    if (breakpointHit && !minimize) {
      setExpanded && setExpanded(true);
    }
  }, [breakpointHit, minimize, setExpanded]);

  return (
    <Card
      inner
      data-testid="release-card"
      className={`release-card pos-rel max-w950 p0 ${!minimize ? 'flex-grow min-w600' : 'min-w320'} flex-basis`}
    >
      {!breakpointHit && (
        <>
          {!minimize && (
            <div data-testid="minimize-button-left" className="minimize-arrow release" onClick={toggleNav}>
              <Icon>chevron_left</Icon>
            </div>
          )}
          {minimize && (
            <div data-testid="minimize-button-right" className="minimize-arrow release" onClick={toggleNav}>
              <Icon>chevron_right</Icon>
            </div>
          )}
        </>
      )}
      <div className="d-flex gap10 p10">
        <div
          className={`br10 text-center min-w300 ${minimize ? 'ml-auto mr-auto' : ''}`}
          style={
            {
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '300px',
              width: '300px',
              backgroundImage: `url(${releaseCycle.imageUrl || Images.releaseCycles.defaultReleaseArtwork})`,
            } as CSSProperties
          }
        >
          <div className="bg-blur-no-bg h100p br10">
            <ReleaseCycleStatus date={releaseCycle.releaseDate} isOnCard />
            <div className="p8">
              <h3 className="truncate-1">{releaseCycle.name}</h3>
              <p className="text-reduced-opacity">{dateTimeFormatter(releaseCycle.releaseDate, 'date')}</p>
              <div className="pos-rel mt10">
                <img src={Images.releaseCycles.vinylCenter} alt="Vinyl Center" className="vinyl-center" />
                <img
                  src={releaseCycle.imageUrl || Images.releaseCycles.defaultReleaseArtwork}
                  alt="Release Cycle"
                  height={180}
                  width={180}
                  className="br50p object-fit-cover vinyl-mask"
                />
              </div>
            </div>
          </div>
        </div>

        {!minimize && !breakpointHit && (
          <div className="flex-grow">
            <h5>{t('RELEASE-CYCLES.TODAYS-TASKS')}</h5>
            <Card innerInner className="p10 mt10">
              {todaysSchedule && todaysSchedule.length === 0 && (
                <>
                  <h5>{t('RELEASE-CYCLES.NO-TASKS-TODAY')}</h5>
                  <p className="text-faded mt4">{t('RELEASE-CYCLES.NO-TASKS-TODAY-DESCRIPTION')}</p>
                </>
              )}
              {todaysSchedule &&
                todaysSchedule.length > 0 &&
                todaysSchedule.map((task: ScheduleTaskModel | ScheduleAdviceModel, index: number) => (
                  <div key={task.id}>
                    <div
                      className={`left-border dot-task-types ${ReleaseCategoryNameFormatter((task as ScheduleTaskModel).category?.value)} pl10`}
                    >
                      <p
                        className={`${(task as ScheduleTaskModel).isCompleted || (task as ScheduleAdviceModel).isRead ? 'text-faded strikethrough' : ''} truncate-1`}
                      >
                        {task.title}
                      </p>
                      {task.itemType === 'task' && (
                        <>
                          <ReleaseCycleTaskCategoryStatus
                            category={(task as ScheduleTaskModel).category.value}
                            time={(task as ScheduleTaskModel).timeEstimateMinutes}
                          />
                          <div className="d-flex gap10">
                            {(task as ScheduleTaskModel).isOneTimeTask && (
                              <div className="mt10">
                                <TaskPill isOneTimeTask={(task as ScheduleTaskModel).isOneTimeTask} />
                              </div>
                            )}
                            {(task as ScheduleTaskModel).isTimeSensitive && (
                              <div className="mt10">
                                <TaskPill isTimeSensitive={(task as ScheduleTaskModel).isTimeSensitive} />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {index < todaysSchedule.length - 1 && (
                      <div className="d-flex form-divider mb10">
                        <div className="line"></div>
                      </div>
                    )}
                  </div>
                ))}
            </Card>
          </div>
        )}
      </div>
      <div
        className="d-flex card-inner-inner p10 br-b-12 br-t-0 cursor-pointer h80"
        onClick={() => {
          navigate(`/release-cycles?id=${releaseCycle.id}`);
          userTracking?.customMixpanelEvent('Release Cycle Card Clicked', {});
        }}
      >
        <div className="p8 bg-blue-gradient br50p h24 w24 pos-rel mt-auto mb-auto text-center">
          <p className="chr-mt2 saf-mt-2">📋</p>
        </div>
        <div className="pl10 mt-auto mb-auto">
          <h5 className="d-flex gap8">
            {t('RELEASE-CYCLES.SEE-TODAYS-TASKS')}
            <BetaTag isGreen />
          </h5>
          <p className="text-faded">
            {tasksCompletedToday}/{totalTasksToday} complete
          </p>
        </div>
        <div className="pos-rel ml-auto mt-auto mb-auto h40">
          <Button className="icon-btn mt0 mr0 btn-grey br50p">
            <Icon>chevron_right</Icon>
          </Button>
          {tasksCompletedToday !== undefined && totalTasksToday !== undefined && (
            <ProgressBar
              progress={(tasksCompletedToday / totalTasksToday) * 100}
              progressButton={(tasksCompletedToday / totalTasksToday) * 100}
              circle
            />
          )}
        </div>
      </div>
    </Card>
  );
};
