import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';

export const EmojiRatingInput = ({
  lowerBoundLabel,
  upperBoundLabel,
  output,
}: {
  lowerBoundLabel?: string;
  upperBoundLabel?: string;
  output: (choice: string) => void;
}) => {
  const [chosenOption, setChosenOption] = useState<string>();

  useEffect(() => {
    if (chosenOption) {
      output(chosenOption);
    }
  }, [chosenOption, output]);

  const csatOptions = [
    { value: '1', label: '😠' },
    { value: '2', label: '🙁' },
    { value: '3', label: '😐' },
    { value: '4', label: '🙂' },
    { value: '5', label: '😍' },
  ];

  return (
    <>
      <div className="d-flex jc-center gap20 w100p">
        {csatOptions.map((option) => (
          <Button
            key={option.value}
            className={`icon-btn p10 ${chosenOption === option.value ? 'bg-blue-gradient' : 'btn-black-2'}`}
            onClick={() => {
              setChosenOption(option.value);
            }}
          >
            <h3>{option.label}</h3>
          </Button>
        ))}
      </div>
      <div className="d-flex jc-space-between">
        <p className="small text-faded">{lowerBoundLabel}</p>
        <p className="small text-faded">{upperBoundLabel}</p>
      </div>
    </>
  );
};
