import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';
import { LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isSameDay, isToday, parseISO } from 'date-fns';
import en from 'date-fns/locale/en-GB';
import { utcToZonedTime } from 'date-fns-tz';

import Card from '@/components/utility/microcomponents/Card';
import ProgressBar from '@/components/utility/ProgressBar';
import useReleaseCycleSchedule from '@/hooks/release-cycles/useReleaseCycleSchedule';
import useReleaseCycleTaskCompletionStats from '@/hooks/release-cycles/useReleaseCycleTaskCompletionStats';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { ReleaseCycleModel, ScheduleAdviceModel, ScheduleTaskModel } from '@/models/ReleaseCycles';

import Loading from '../utility/Loading';
import ReleaseCycleStatus from '../utility/statuses/ReleaseCycleStatus';
import { ScheduleAdviceCard } from './ScheduleAdviceCard';
import { ScheduleTaskCard } from './ScheduleTaskCard';

type CustomDayProps = PickersDayProps<Date> & { day: Date };

const ReleaseCycleDayView = ({
  releaseCycle,
  viewCompleted,
  toggleCompleted,
}: {
  releaseCycle: ReleaseCycleModel;
  viewCompleted: boolean;
  toggleCompleted?: () => void;
}) => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const [completedScheduleLoading, setCompletedScheduleLoading] = useState<boolean>(true);
  const [scheduleTasksLoading, setScheduleTasksLoading] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString());
  const [taskDate, setTaskDate] = useState<string>('');
  const [dailyTasks, setDailyTasks] = useState<(ScheduleTaskModel | ScheduleAdviceModel)[]>([]);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { releaseCycleSchedule, refetchReleaseCycleSchedule } = useReleaseCycleSchedule({
    scheduleId: releaseCycle.id,
  });

  const { releaseCyclesCompletionStats, refetchReleaseCyclesCompletionStats } = useReleaseCycleTaskCompletionStats({
    scheduleId: releaseCycle.id,
  });

  const tasksCompleted = releaseCyclesCompletionStats?.reduce(
    (total, customDate) => total + (customDate.completed || 0),
    0
  );

  const tasksTotal = releaseCyclesCompletionStats?.reduce((total, customDate) => total + (customDate.total || 0), 0);

  const [startOfSchedule, setStartOfSchedule] = useState<Date>();
  const [endOfSchedule, setEndOfSchedule] = useState<Date>();

  const isReleaseDay = () => {
    return releaseCycle.releaseDate && isSameDay(new Date(releaseCycle.releaseDate), new Date(selectedDate));
  };

  const parseAPIDate = (dateString: string) => {
    return new Date(`${dateString}T00:00:00.000Z`);
  };

  useEffect(() => {
    if (releaseCycleSchedule) {
      const sortedSchedule = releaseCycleSchedule.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      const startUTC = parseAPIDate(sortedSchedule[0].date);
      const endUTC = parseAPIDate(sortedSchedule[sortedSchedule.length - 1].date);

      const startSchedule = utcToZonedTime(startUTC, timeZone);
      const endSchedule = utcToZonedTime(endUTC, timeZone);

      setStartOfSchedule(startSchedule);
      setEndOfSchedule(endSchedule);
      setCompletedScheduleLoading(false);
    }
  }, [releaseCycleSchedule, timeZone]);

  useEffect(() => {
    if (releaseCycleSchedule) {
      const selectedLocal = utcToZonedTime(parseISO(selectedDate), timeZone);
      const selectedFormatted = format(selectedLocal, 'yyyy-MM-dd');

      const newDailyTasks = releaseCycleSchedule.filter((task) => {
        const taskUTC = parseAPIDate(task.date);
        const taskLocal = utcToZonedTime(taskUTC, timeZone);
        return format(taskLocal, 'yyyy-MM-dd') === selectedFormatted;
      });

      if (newDailyTasks.length > 0) {
        setTaskDate(newDailyTasks[0].date);
        setDailyTasks(newDailyTasks[0].items);
      } else {
        setDailyTasks([]);
      }

      setScheduleTasksLoading(false);
    }
  }, [releaseCycleSchedule, selectedDate, timeZone]);

  const updateList = useCallback(
    async (id: string) => {
      if (!releaseCycleSchedule) return;
      releaseCycleSchedule.map((customDate) => {
        if (isSameDay(new Date(customDate.date), new Date(selectedDate))) {
          return {
            ...customDate,
            items: customDate.items.filter((item) => item.id !== id),
          };
        }
        return customDate;
      });

      refetchReleaseCycleSchedule();
      refetchReleaseCyclesCompletionStats();
    },
    [refetchReleaseCycleSchedule, refetchReleaseCyclesCompletionStats, releaseCycleSchedule, selectedDate]
  );

  useEffect(() => {
    if (!releaseCyclesCompletionStats) return;
    if (releaseCyclesCompletionStats) {
      setCompletedScheduleLoading(false);
    }
  }, [releaseCyclesCompletionStats]);

  const renderDay = useCallback(
    (props: CustomDayProps) => {
      if (!releaseCyclesCompletionStats) {
        return <PickersDay {...props}>{props.day.getDate()}</PickersDay>;
      }

      const localPropsDay = new Date(props.day);

      const today = utcToZonedTime(new Date(), timeZone);

      const releaseDateLocal = utcToZonedTime(parseISO(releaseCycle.releaseDate), timeZone);

      const isToday = isSameDay(localPropsDay, today);
      const releaseDate = isSameDay(localPropsDay, releaseDateLocal);

      const isCustomDate = releaseCyclesCompletionStats.some((customDate) => {
        const utcDate = new Date(`${customDate.date}T00:00:00Z`);
        const localDate = utcToZonedTime(utcDate, timeZone);
        return isSameDay(localPropsDay, localDate);
      });

      const dateStats = releaseCyclesCompletionStats.find((customDate) => {
        const utcDate = new Date(`${customDate.date}T00:00:00Z`);
        const localDate = utcToZonedTime(utcDate, timeZone);
        return isSameDay(localPropsDay, localDate);
      });

      const dateTotal = dateStats?.total;
      const dateCompleted = dateStats?.completed;
      const dateProgress = dateTotal && dateCompleted ? (dateCompleted / dateTotal) * 100 : undefined;

      const selectedDateLocal = utcToZonedTime(new Date(selectedDate), timeZone);
      const isSameDaySelected = isSameDay(selectedDateLocal, localPropsDay);

      return (
        <PickersDay
          key={`${props.day.toISOString()}-${releaseCyclesCompletionStats ? releaseCyclesCompletionStats.length : 0}`}
          className={`${isToday ? 'today' : ''} ${releaseDate ? 'release-date-border' : ''}`}
          selected={isSameDaySelected}
          {...props}
        >
          <div className="inner-content">
            {isCustomDate && dateProgress !== undefined && dateProgress > 0 && (
              <ProgressBar
                color="primary"
                progress={dateProgress}
                progressDate={dateProgress}
                circle
                isPast={!isToday}
                isSelected={isSameDaySelected}
              />
            )}
            <div
              className={`date-inner br48 ${isToday || isSameDaySelected || releaseDate ? 'text-white' : 'text-faded'}`}
            >
              {props.day.getDate()}
            </div>
          </div>
          {isCustomDate && <div className={`day-dot ${isToday || isSameDaySelected ? 'isToday' : ''}`}></div>}
        </PickersDay>
      );
    },
    [releaseCycle.releaseDate, releaseCyclesCompletionStats, selectedDate, timeZone]
  );

  if (completedScheduleLoading && scheduleTasksLoading) {
    return (
      <div className="centered-loading mt100">
        <Loading />
      </div>
    );
  }

  return (
    <div className="mt20">
      <div className={`mt10 ${breakpointHit ? 'w100p jc-center d-flex flex-d-col ' : 'd-flex'} gap20`}>
        {completedScheduleLoading && (
          <div className="centered-loading">
            <Loading size="small" />
          </div>
        )}
        {!completedScheduleLoading && (
          <div className={`${!breakpointHit ? 'w-fit' : 'w100p'} ml-auto mr-auto`}>
            {releaseCycle.id !== '' && <ReleaseCycleStatus date={releaseCycle.releaseDate} />}
            <Card inner className={`w100p p8 ${releaseCycle.id !== '' ? 'mt20' : ''} pb20`} id="release-cycle-calender">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  key={
                    releaseCyclesCompletionStats
                      ? JSON.stringify(releaseCyclesCompletionStats) + new Date().toISOString()
                      : 'default'
                  }
                  orientation="portrait"
                  minDate={startOfSchedule}
                  maxDate={endOfSchedule}
                  displayStaticWrapperAs="desktop"
                  slotProps={{
                    toolbar: {
                      hidden: true,
                    },
                  }}
                  slots={{
                    day: renderDay,
                  }}
                  views={['day']}
                  dayOfWeekFormatter={(_, date) => `${format(date, 'EEE', { locale: en }).toUpperCase()}`}
                  value={parseISO(selectedDate)}
                  onChange={(value) => {
                    if (value) {
                      setSelectedDate(value.toISOString());
                    }
                  }}
                />
              </LocalizationProvider>
            </Card>
            <Card inner className="mt20 p16">
              {tasksCompleted !== undefined && (
                <div className="d-flex jc-space-between w100p">
                  <h5 className="mb8">{t('RELEASE-CYCLES.ACTIVITY-PROGRESS')}:</h5>
                  <p>
                    {tasksTotal !== undefined && tasksCompleted !== undefined ? `${tasksCompleted}/${tasksTotal}` : ''}
                  </p>
                </div>
              )}
              {tasksCompleted !== undefined && tasksCompleted > 0 && (
                <ProgressBar
                  color="primary"
                  progress={tasksTotal && tasksCompleted ? (tasksCompleted / tasksTotal) * 100 : 0}
                />
              )}

              {tasksCompleted === 0 && (
                <Card innerInner className="p8 text-center">
                  <p className="text-faded max-w230 ml-auto mr-auto">{t('RELEASE-CYCLES.NO-COMPLETED-TASKS-TODAY')}</p>
                </Card>
              )}
            </Card>
          </div>
        )}
        {scheduleTasksLoading && (
          <div className="centered-loading w100p">
            <Loading size="small" />
          </div>
        )}
        {!scheduleTasksLoading && dailyTasks && (
          <>
            {dailyTasks.length > 0 && (
              <Card
                id="release-cycle-daily-tasks"
                inner
                className={`flex-grow h-fit ${breakpointHit ? 'p0 no-bg mb20' : 'p16 w120p'} ${isReleaseDay() ? 'release-date' : ''}`}
              >
                {tasksTotal !== undefined && tasksCompleted !== undefined && tasksTotal !== tasksCompleted && (
                  <h5 className={'mb10'}>
                    {isToday(parseISO(selectedDate))
                      ? t('RELEASE-CYCLES.TODAYS-TASKS')
                      : `${format(parseISO(selectedDate), 'do LLL')} actions`}
                  </h5>
                )}

                {dailyTasks.every(
                  (item) => (item as ScheduleTaskModel).isCompleted || (item as ScheduleAdviceModel).isRead
                ) &&
                  ((!breakpointHit && !viewCompleted) || breakpointHit) && (
                    <div className="mb10">
                      <p className="text-faded mt4">{t('RELEASE-CYCLES.ALL-TASKS-COMPLETED-TODAY-DESCRIPTION')}</p>
                    </div>
                  )}
                <div className={breakpointHit ? 'd-flex overflow-x-scroll gap10' : ''}>
                  {dailyTasks.map((item, index) => {
                    if (
                      (breakpointHit || !viewCompleted) &&
                      ((item as ScheduleAdviceModel).isRead || (item as ScheduleTaskModel).isCompleted)
                    )
                      return null;
                    if (item.itemType === 'task') {
                      return (
                        <div key={index} className={index === 0 || breakpointHit ? '' : 'mt10'}>
                          <ScheduleTaskCard
                            key={index}
                            isCard={breakpointHit}
                            index={index}
                            task={item as ScheduleTaskModel}
                            updateList={updateList}
                            date={taskDate}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={index} className={index === 0 || breakpointHit ? '' : 'mt10'}>
                          <ScheduleAdviceCard
                            key={index}
                            index={index}
                            isCard={breakpointHit}
                            advice={item as ScheduleAdviceModel}
                            updateList={updateList}
                            date={taskDate}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                {dailyTasks.some((item) => {
                  if (
                    breakpointHit &&
                    ((item as ScheduleAdviceModel).isRead || (item as ScheduleTaskModel).isCompleted)
                  )
                    return true;
                  return false;
                }) && (
                  <>
                    {viewCompleted && (
                      <div className="d-flex jc-space-between">
                        <h5 className="mt-auto mb-auto">Completed tasks</h5>
                        <Button
                          onClick={() => {
                            toggleCompleted && toggleCompleted();
                          }}
                          className="btn-grey br50p m0"
                        >
                          <Icon className="fs-16 pr8 material-symbols-outlined">visibility_off</Icon>
                          Hide
                        </Button>
                      </div>
                    )}
                    {!viewCompleted && (
                      <Button
                        onClick={() => {
                          toggleCompleted && toggleCompleted();
                        }}
                        className="btn-grey br50p m0 w100p"
                      >
                        <Icon className="fs-16 pr8 material-symbols-outlined">visibility</Icon>
                        Show completed tasks
                      </Button>
                    )}
                    {viewCompleted && (
                      <div className={breakpointHit ? 'd-flex overflow-x-scroll gap10 mt10 mb20' : ''}>
                        {dailyTasks.map((item, index) => {
                          if (
                            viewCompleted &&
                            breakpointHit &&
                            ((item as ScheduleAdviceModel).isRead || (item as ScheduleTaskModel).isCompleted)
                          ) {
                            if (item.itemType === 'task') {
                              return (
                                <ScheduleTaskCard
                                  key={index}
                                  isCard={breakpointHit}
                                  index={index}
                                  task={item as ScheduleTaskModel}
                                  updateList={updateList}
                                  date={taskDate}
                                />
                              );
                            } else {
                              return (
                                <ScheduleAdviceCard
                                  key={index}
                                  index={index}
                                  isCard={breakpointHit}
                                  advice={item as ScheduleAdviceModel}
                                  updateList={updateList}
                                  date={taskDate}
                                />
                              );
                            }
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    )}
                  </>
                )}
              </Card>
            )}
            {dailyTasks.length === 0 && (
              <Card id="release-cycle-daily-tasks" inner className="flex-grow h-fit p16">
                <h5>{t('RELEASE-CYCLES.NO-TASKS-TODAY')}</h5>
                <p className="text-faded mt4">{t('RELEASE-CYCLES.NO-TASKS-TODAY-DESCRIPTION')}</p>
              </Card>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReleaseCycleDayView;
