import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Autocomplete, Button, CircularProgress, Icon, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import Loading from '@/components/utility/Loading';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useSpotifyArtistAlbums from '@/hooks/playlist/useSpotifyArtistAlbums';
import useSpotifyArtistSingles from '@/hooks/playlist/useSpotifyArtistSingles';
import useSpotifySearchArtistTracks from '@/hooks/playlist/useSpotifySearchArtistTracks';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { OnboardingSteps } from '@/models/Enums';
import { ReleaseCycleModel } from '@/models/ReleaseCycles';
import { SpotifyChosenTracksModel } from '@/models/Spotify';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';

import AlbumTracksModal from '../promote-modals/PlaylistingModal/playlisting-steps/AlbumTracksModal';
import Card from '../utility/microcomponents/Card';

const BoostReleasedTracksPage = ({ closeModal }: { closeModal?: (isRefetch?: boolean) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useAccountContext();
  const { breakpointHit } = useBreakpoints();
  const { setOnboardingStep } = useOnboardingContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { artist } = useArtist();

  const [trackSearchValue, setTrackSearchValue] = useState<string>('');
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>(uuidv4());

  const [chosenTrack, setChosenTrack] = useState<SpotifyChosenTracksModel>();
  const [activeAlbumId, setActiveAlbumId] = useState<string>();
  const [showAlbumModal, setShowAlbumModal] = useState<boolean>(false);
  const { watch, setValue } = useFormContext();
  const releaseDate = watch('releaseDetails.releaseDate');
  const releaseName = watch('releaseDetails.releaseTitle');
  const releaseArtwork = watch('releaseDetails.releaseArtwork');
  const releaseSpotifyId = watch('releaseDetails.releaseSpotifyId');

  const { artistSingles, artistSinglesIsLoading } = useSpotifyArtistSingles({
    spotifyId: artist?.platformIds.spotifyId,
  });
  const { artistAlbums, artistAlbumsIsLoading } = useSpotifyArtistAlbums({
    spotifyId: artist?.platformIds.spotifyId,
  });
  const { searchArtistTracksOptions, searchArtistTracksOptionsIsLoading, refetchSearchArtistTracksOptions } =
    useSpotifySearchArtistTracks({
      artistName: artist?.details.name,
      search: trackSearchValue,
    });

  const activeAlbum = useMemo(() => {
    if (!artistAlbums) return;

    return artistAlbums.find((item) => item.id === activeAlbumId);
  }, [activeAlbumId, artistAlbums]);

  useEffect(() => {
    if (chosenTrack) {
      setValue('chosenTrack', chosenTrack);
      setValue('releaseDetails.releaseSpotifyId', chosenTrack.id);
      setValue('releaseDetails.releaseTitle', chosenTrack.name);
      setValue('releaseDetails.releaseArtwork', chosenTrack.images[0].url);
      setValue('releaseDetails.releaseDate', chosenTrack.release_date);
    }
  }, [chosenTrack, setValue]);

  useEffect(() => {
    if (trackSearchValue.length) {
      refetchSearchArtistTracksOptions();
    }
  }, [refetchSearchArtistTracksOptions, trackSearchValue]);

  const handleResetTracksAutocomplete = useCallback(async () => {
    setTrackSearchValue('');
    setAutoCompleteKey(uuidv4());
    await refetchSearchArtistTracksOptions(undefined);
  }, [refetchSearchArtistTracksOptions]);

  const handleUpdateTrack = useCallback(
    async (value: SpotifyChosenTracksModel) => {
      setChosenTrack(value);

      await handleResetTracksAutocomplete();
    },
    [handleResetTracksAutocomplete]
  );
  const handleUpdateAlbumTrack = useCallback(async (albumTracks: SpotifyChosenTracksModel[]) => {
    setChosenTrack(albumTracks[0]);
  }, []);

  const createRelease = () => {
    if (!accountId) return;
    setIsLoading(true);
    ReleaseCyclesAPI.createReleaseCycle({
      data: {
        accountId: accountId,
        name: releaseName,
        releaseDate: format(new Date(releaseDate), 'yyyy-MM-dd'),
        imageUrl: releaseArtwork,
        spotifyId: releaseSpotifyId,
      },
    })
      .then(async (resp: AxiosResponse<ReleaseCycleModel>) => {
        closeModal && closeModal(true);
        navigate(`/release-cycles?id=${resp.data.id}`);
        setIsLoading(false);
      })
      .catch((error) => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: error.response.data.errorMessage,
            type: 'error',
          },
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {activeAlbum && (
        <AlbumTracksModal
          open={showAlbumModal}
          isSingleSelection={true}
          onClose={() => {
            setActiveAlbumId(undefined);
            setShowAlbumModal(false);
          }}
          album={activeAlbum}
          chosenTracks={chosenTrack ? [chosenTrack] : []}
          handleUpdateAlbumTrack={handleUpdateAlbumTrack}
        />
      )}
      <div className="mt40 max-w1000 mr-auto ml-auto">
        <h3 data-testid="choose-songs-title">{t('PLAYLISTING.CHOOSE-YOUR-SONGS')}</h3>
        <p className="text-faded mb48-lg-up mb20-lg-down mt16">{t('PLAYLISTING.CHOOSE-YOUR-SONGS-DESCRIPTION')}</p>
      </div>
      {!artist && (
        <Card className="max-w450 ml-auto mr-auto text-left">
          <h4>{t('COMMON.CONNECT-YOUR-SPOTIFY-ACCOUNT')}</h4>
          <p className="text-faded mt10">{t('COMMON.CONNECT-YOUR-SPOTIFY-ACCOUNT-DESCRIPTION')}</p>
          <Button
            className="w100p m0 btn-white mt20"
            onClick={() =>
              navigate('/profile', { state: { redirect: `${window.location.pathname}?releaseCycleFlow=true` } })
            }
          >
            {t('COMMON.CONNECT-ACCOUNT')}
          </Button>
        </Card>
      )}
      {artist && (
        <div className="max-w1000 ml-auto mr-auto mb200">
          <div className="card mb20">
            <Autocomplete
              key={autoCompleteKey}
              options={searchArtistTracksOptions}
              noOptionsText={<p className="text-white">No options available</p>}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={() => true}
              onBlur={handleResetTracksAutocomplete}
              loading={searchArtistTracksOptionsIsLoading}
              loadingText={<Loading size="small" />}
              onChange={(_, value) => {
                if (!value) return;
                handleUpdateTrack({
                  id: value.id,
                  name: value.name,
                  type: 'single',
                  artists: value.artists,
                  images: value.album.images,
                  release_date: value.album.release_date,
                });
                setTrackSearchValue('');
              }}
              onInputChange={(_, value) => {
                setTrackSearchValue(value);
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.id} style={{ backgroundColor: '#131313' }}>
                  <div>
                    <img className="search-list-image br8" src={option.album?.images[0]?.url} alt="" />
                  </div>
                  <div className="d-flex">
                    <div className="pl16 text-white">
                      <p>{option.name}</p>
                      <p className="small text-faded mt-8">{option.artists[0].name}</p>
                      <p className="small text-faded mt-8">{option.album?.name}</p>
                    </div>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('PLAYLISTING.SEARCH-FOR-TRACKS')}
                  sx={{
                    '& .MuiInputLabel-outlined': {
                      paddingLeft: '20px',
                      borderColor: 'white',
                    },
                    '& .MuiInputLabel-shrink': {
                      marginLeft: '20px',
                      paddingLeft: '10px',
                      paddingRight: 0,
                      borderColor: 'white',
                    },
                    '& .MuiAutocomplete-listbox': {
                      maxHeight: 200,
                      overflow: 'auto',
                    },
                  }}
                  InputLabelProps={params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <Icon className="text-faded">search</Icon>,
                  }}
                />
              )}
            />
          </div>

          <div className="card mb20 d-flex gap20 flex-wrap">
            {artistAlbums && artistAlbums?.length > 0 && (
              <div className="w100p text-left hide-lg-up">
                <h5>Albums</h5>
                {artistAlbumsIsLoading ? (
                  <div className="mt70">
                    <Loading size="small" />
                  </div>
                ) : (
                  <div className="d-flex gap20 mt20 jc-space-between overflow-scroll pb10">
                    {artistAlbums?.map((album, index) => (
                      <div
                        key={album.id}
                        data-testid={`playlisting-album-item-${index}`}
                        className="playlisting-album-item flex-grow list-item-parent cursor-pointer"
                        style={
                          {
                            '--animation-number': `${index}`,
                          } as React.CSSProperties
                        }
                        onClick={() => {
                          setActiveAlbumId(album.id);
                          setShowAlbumModal(true);
                        }}
                      >
                        <img src={album.images[0].url} alt="" />
                        <p className="text-center">{album.name}</p>
                        <p className="text-center small text-faded">{format(new Date(album.release_date), 'yyyy')}</p>
                      </div>
                    ))}
                  </div>
                )}
                <div className="d-flex form-divider mt10 mb20">
                  <div className="line"></div>
                </div>
              </div>
            )}
            <div className="flex-w50p-21 w100p-lg-down text-left">
              <h5>Singles</h5>
              {artistSinglesIsLoading ? (
                <div className="mt70 mb48">
                  <Loading size="small" />
                </div>
              ) : (
                artistSingles?.map((single, index) => (
                  <div key={single.id}>
                    {single.tracks.map((track, trackIndex) => (
                      <ButtonComponent
                        isCustom={true}
                        key={track.id}
                        dataTestId={`playlisting-single-track-item-${index}-${trackIndex}`}
                        style={
                          {
                            '--animation-number': `${index}`,
                          } as React.CSSProperties
                        }
                        className={`mt20 w100p ${
                          chosenTrack?.id === track.id ? 'to-do-card-border' : 'to-do-card-pre-border'
                        }`}
                        onClick={() =>
                          handleUpdateTrack({
                            id: track.id,
                            name: track.name,
                            type: 'single',
                            artists: track.artists,
                            images: single.images,
                            release_date: single.release_date,
                          })
                        }
                      >
                        <div className={`list-item list-item-parent campaign card-inner d-flex w100p p12`}>
                          <img src={single.images[0].url} alt="" />
                          <div className="mt-auto mb-auto text-left">
                            <p>{track.name}</p>
                            <p className="text-faded small">
                              {track.artists.map(
                                (item, index) => `${item.name}${index < track.artists.length - 1 ? ', ' : ''}`
                              )}
                            </p>
                            <p className="text-faded small">
                              Released on: {format(new Date(single.release_date), 'yyyy-MM-dd')}
                            </p>
                          </div>
                          <div className="ml-auto mt-auto mb-auto">
                            {chosenTrack?.id === track.id ? (
                              <div>
                                <Icon className="text-blue">check_circle</Icon>
                              </div>
                            ) : (
                              <div>
                                <Icon className="material-symbols-outlined text-faded">circle</Icon>
                              </div>
                            )}
                          </div>
                        </div>
                      </ButtonComponent>
                    ))}
                  </div>
                ))
              )}
            </div>
            <div className="vertical-divider"></div>
            <div className="flex-w50p-21 text-left hide-lg-down">
              <h5>Albums</h5>
              {artistAlbumsIsLoading ? (
                <div className="mt70 mb48">
                  <Loading size="small" />
                </div>
              ) : (
                <div className="d-flex flex-item-wrap gap20 mt20 jc-center">
                  {artistAlbums?.map((album, index) => (
                    <div
                      key={index}
                      className="playlisting-album-item flex-grow list-item-parent cursor-pointer"
                      style={
                        {
                          '--animation-number': `${index}`,
                        } as React.CSSProperties
                      }
                      onClick={() => {
                        setActiveAlbumId(album.id);
                        setShowAlbumModal(true);
                      }}
                    >
                      <img src={album.images[0].url} alt="" />
                      <p className="text-center">{album.name}</p>
                      <p className="text-center small text-faded">{format(new Date(album.release_date), 'yyyy')}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="onboarding-footer">
        <div className="d-flex jc-space-between mt40">
          {chosenTrack && (
            <div className="text-left pl40-lg-up pl20-lg-down">
              <h5>Promote my track</h5>
              <p className={` ${breakpointHit ? 'max-w200 truncate-1' : 'mt8'}`}>{chosenTrack?.name}</p>
            </div>
          )}
          <div className="ml-auto d-flex gap10">
            <Button
              className={breakpointHit ? 'icon-btn m0' : 'border-btn min-w130 m0'}
              onClick={() => {
                setOnboardingStep(OnboardingSteps.RELEASE_TYPE_CHOICE);
              }}
            >
              {breakpointHit ? <Icon>chevron_left</Icon> : t('COMMON.BACK')}
            </Button>
            <Button
              className="btn-white min-w130 m0"
              disabled={chosenTrack === undefined || isLoading}
              onClick={() => {
                createRelease();
              }}
            >
              {isLoading ? <CircularProgress size={16} /> : t('COMMON.CONTINUE')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoostReleasedTracksPage;
