import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';
import { addDays, format, isAfter, isBefore, isSameDay, parseISO, startOfWeek } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import Card from '@/components/utility/microcomponents/Card';
import ProgressBar from '@/components/utility/ProgressBar';
import useReleaseCycleSchedule from '@/hooks/release-cycles/useReleaseCycleSchedule';
import useReleaseCycleTaskCompletionStats from '@/hooks/release-cycles/useReleaseCycleTaskCompletionStats';
import { ReleaseCycleModel, ScheduleAdviceModel, ScheduleTaskModel } from '@/models/ReleaseCycles';

import Loading from '../utility/Loading';
import ReleaseCycleStatus from '../utility/statuses/ReleaseCycleStatus';
import { ScheduleAdviceCard } from './ScheduleAdviceCard';
import { ScheduleTaskCard } from './ScheduleTaskCard';

const ReleaseCycleWeekView = ({
  releaseCycle,
  viewCompleted,
}: {
  releaseCycle: ReleaseCycleModel;
  viewCompleted: boolean;
}) => {
  const { t } = useTranslation();
  const [completedScheduleLoading, setCompletedScheduleLoading] = useState<boolean>(true);
  const [scheduleTasksLoading, setScheduleTasksLoading] = useState<boolean>(true);

  const [startOfSchedule, setStartOfSchedule] = useState<Date>();
  const [endOfSchedule, setEndOfSchedule] = useState<Date>();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [selectedDateRange, setSelectedDateRange] = useState<Date[]>([
    startOfWeek(new Date(), { weekStartsOn: 1 }),
    addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 6),
  ]);

  const { releaseCycleSchedule, refetchReleaseCycleSchedule } = useReleaseCycleSchedule({
    scheduleId: releaseCycle.id,
  });

  const { releaseCyclesCompletionStats, refetchReleaseCyclesCompletionStats } = useReleaseCycleTaskCompletionStats({
    scheduleId: releaseCycle.id,
  });

  const tasksCompleted = releaseCyclesCompletionStats?.reduce(
    (total, customDate) => total + (customDate.completed || 0),
    0
  );

  const tasksTotal = releaseCyclesCompletionStats?.reduce((total, customDate) => total + (customDate.total || 0), 0);

  const updateList = useCallback(
    (id: string) => {
      if (!releaseCycleSchedule) return;
      releaseCycleSchedule.map((customDate) => {
        if (isSameDay(new Date(customDate.date), new Date(selectedDateRange[0]))) {
          return {
            ...customDate,
            items: customDate.items.filter((item) => item.id !== id),
          };
        }
        return customDate;
      });
      refetchReleaseCycleSchedule();
      refetchReleaseCyclesCompletionStats();
    },
    [refetchReleaseCycleSchedule, refetchReleaseCyclesCompletionStats, releaseCycleSchedule, selectedDateRange]
  );

  const thisWeek = useMemo(() => {
    const week: Date[] = [];
    const startDate = startOfWeek(new Date(selectedDateRange[0]), { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      week.push(addDays(startDate, i));
    }
    return week;
  }, [selectedDateRange]);

  const datesAndTasks = useMemo(() => {
    if (!releaseCycleSchedule) return [];

    return thisWeek.map((day) => {
      let tasks: (ScheduleTaskModel | ScheduleAdviceModel)[] = [];
      let taskDay: string = '';

      const localDay = utcToZonedTime(day, timeZone);

      const customDate = releaseCycleSchedule.find((customDate) => {
        const utcDate = parseISO(`${customDate.date}T00:00:00Z`);
        const localDate = utcToZonedTime(utcDate, timeZone);
        return isSameDay(localDay, localDate);
      });

      if (customDate) {
        tasks = customDate.items;
        taskDay = customDate.date;
      }

      return { date: localDay, tasks, taskDate: taskDay };
    });
  }, [releaseCycleSchedule, thisWeek, timeZone]);

  const isToday = (day: Date) => {
    return isSameDay(new Date(), day);
  };

  const isReleaseDay = (day: Date) => {
    return releaseCycle.releaseDate && isSameDay(new Date(releaseCycle.releaseDate), day);
  };

  useEffect(() => {
    if (releaseCycleSchedule) {
      const sortedSchedule = releaseCycleSchedule.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      const start = sortedSchedule[0].date;
      const end = sortedSchedule[sortedSchedule.length - 1].date;

      const startUTC = zonedTimeToUtc(new Date(`${start}T00:00:00Z`), 'UTC');
      const endUTC = zonedTimeToUtc(new Date(`${end}T00:00:00Z`), 'UTC');

      const startSchedule = utcToZonedTime(startUTC, timeZone);

      const endSchedule = utcToZonedTime(endUTC, timeZone);

      setStartOfSchedule(startSchedule);
      setEndOfSchedule(endSchedule);
      setCompletedScheduleLoading(false);
    }
  }, [releaseCycleSchedule, timeZone]);

  useEffect(() => {
    if (releaseCyclesCompletionStats) {
      setScheduleTasksLoading(false);
    }
  }, [releaseCyclesCompletionStats]);

  if (completedScheduleLoading && scheduleTasksLoading) {
    return (
      <div className="centered-loading mt100">
        <Loading />
      </div>
    );
  }

  return (
    <div className="">
      <div className="d-flex w100p gap20 pt20 pb20 pl0 bg-bg t80 pos-sticky z-1000">
        {releaseCycle.id !== '' && <ReleaseCycleStatus date={releaseCycle.releaseDate} />}
        <Card inner className="d-flex p16 gap10">
          <h5 className="flex-grow">
            {tasksTotal && tasksCompleted ? `${tasksCompleted}/${tasksTotal}` : 0} {t('RELEASE-CYCLES.TASKS-COMPLETE')}
          </h5>
          <div className="min-w200">
            <ProgressBar
              color="primary"
              progress={tasksTotal && tasksCompleted ? (tasksCompleted / tasksTotal) * 100 : 0}
            />
          </div>
        </Card>
      </div>

      <Card inner className="calendar-card pos-rel">
        <div className="d-flex jc-center">
          <Button
            className="m0"
            disabled={startOfSchedule !== undefined && isBefore(selectedDateRange[0], startOfSchedule)}
            onClick={() => {
              setSelectedDateRange([
                new Date(addDays(parseISO(selectedDateRange[0].toISOString()), -7)),
                new Date(addDays(parseISO(selectedDateRange[1].toISOString()), -7)),
              ]);
            }}
          >
            <Icon>keyboard_arrow_left</Icon>
          </Button>
          <p className="mt-auto mb-auto">
            <span className="fw-bold">{format(selectedDateRange[0], 'dd/MM/yy')}</span>
            {` - `}
            <span className="fw-bold">{format(selectedDateRange[1], 'dd/MM/yy')}</span>
          </p>
          <Button
            className="m0"
            disabled={endOfSchedule !== undefined && isAfter(selectedDateRange[1], endOfSchedule)}
            onClick={() => {
              setSelectedDateRange([
                new Date(addDays(parseISO(selectedDateRange[0].toISOString()), 7)),
                new Date(addDays(parseISO(selectedDateRange[1].toISOString()), 7)),
              ]);
            }}
          >
            <Icon>keyboard_arrow_right</Icon>
          </Button>
        </div>

        <div className="d-flex calendar-week jc-space-between overflow-scroll">
          {scheduleTasksLoading && (
            <div className="centered-loading mt100">
              <Loading size="small" />
            </div>
          )}
          {!scheduleTasksLoading &&
            thisWeek.map((day, index) => (
              <div
                key={index}
                className={`flex calendar-day d-flex p10 flex-d-col min-w180 text-center flex-grow ${isToday(day) ? 'day-selected' : ''} ${isReleaseDay(day) ? 'release-date' : ''} ${index === 0 ? '' : 'border-left'}`}
              >
                <div className={`mt10 ${isToday(day) ? 'text-white' : 'text-faded'}`}>
                  {format(day, 'EEE').toUpperCase()}
                </div>
                <div className="w100p mt2">
                  <div className={`date m0 ml-auto mr-auto ${isToday(day) ? 'text-white selected' : 'text-faded'}`}>
                    <p className={`date-text ${isToday(day) ? 'text-white' : 'text-faded'}`}>{format(day, 'd')}</p>
                  </div>
                </div>
                <div className="mt10 flex-grow d-flex flex-d-col">
                  {datesAndTasks?.map((date) => {
                    if (isSameDay(date.date, day)) {
                      return (
                        <React.Fragment key={date.date.toISOString()}>
                          {date.tasks.length > 0 &&
                            date.tasks.map((task, index) => {
                              if (
                                !viewCompleted &&
                                ((task as ScheduleAdviceModel).isRead || (task as ScheduleTaskModel).isCompleted)
                              ) {
                                return null;
                              }
                              if (task.itemType === 'task') {
                                return (
                                  <ScheduleTaskCard
                                    key={task.id}
                                    task={task as ScheduleTaskModel}
                                    index={index}
                                    isWeek
                                    updateList={updateList}
                                    date={date.taskDate}
                                  />
                                );
                              } else {
                                return (
                                  <ScheduleAdviceCard
                                    key={task.id}
                                    updateList={updateList}
                                    date={date.taskDate}
                                    advice={task as ScheduleAdviceModel}
                                    index={index}
                                    isWeek
                                  />
                                );
                              }
                            })}
                          {date.tasks.length === 0 &&
                            !(
                              endOfSchedule &&
                              startOfSchedule &&
                              (isAfter(day, endOfSchedule) || isBefore(day, startOfSchedule))
                            ) && (
                              <div className="p10 text-center mt-auto-50p mb-auto text-faded half-fade-in opacity-0-4">
                                {t('RELEASE-CYCLES.NO-TASKS-THIS-DAY-HAVE-A-DAY-OFF')}
                              </div>
                            )}
                          {date.tasks.length === 0 && startOfSchedule && isBefore(day, startOfSchedule) && (
                            <div className="p10 text-center mt-auto-50p mb-auto text-faded half-fade-in opacity-0-4">
                              {t('RELEASE-CYCLES.BEFORE-RELEASE-PLACEHOLDER')}
                            </div>
                          )}
                          {date.tasks.length === 0 && endOfSchedule && isAfter(day, endOfSchedule) && (
                            <div className="p10 text-center mt-auto-50p mb-auto text-faded half-fade-in opacity-0-4">
                              {t('RELEASE-CYCLES.AFTER-RELEASE-PLACEHOLDER')}
                            </div>
                          )}
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            ))}
        </div>
      </Card>
    </div>
  );
};

export default ReleaseCycleWeekView;
