import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionSummary, Button, Icon } from '@mui/material';
import { addDays, format, isSameDay, parseISO, startOfWeek } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import useReleaseCycleSchedule from '@/hooks/release-cycles/useReleaseCycleSchedule';
import useReleaseCycleTaskCompletionStats from '@/hooks/release-cycles/useReleaseCycleTaskCompletionStats';
import { ReleaseCycleModel, ScheduleAdviceModel, ScheduleTaskModel } from '@/models/ReleaseCycles';

import Loading from '../utility/Loading';
import { ScheduleAdviceCard } from './ScheduleAdviceCard';
import { ScheduleTaskCard } from './ScheduleTaskCard';

const ReleaseCycleWeekViewMobile = ({
  releaseCycle,
  viewCompleted,
  toggleCompleted,
}: {
  releaseCycle: ReleaseCycleModel;
  viewCompleted: boolean;
  toggleCompleted?: () => void;
}) => {
  const { t } = useTranslation();
  const [completedScheduleLoading, setCompletedScheduleLoading] = useState<boolean>(true);
  const [scheduleTasksLoading, setScheduleTasksLoading] = useState<boolean>(true);
  const [weekExpanded, setWeekExpanded] = useState<{ [key: number]: boolean }>({});
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { releaseCycleSchedule, refetchReleaseCycleSchedule } = useReleaseCycleSchedule({
    scheduleId: releaseCycle.id,
  });

  const { releaseCyclesCompletionStats, refetchReleaseCyclesCompletionStats } = useReleaseCycleTaskCompletionStats({
    scheduleId: releaseCycle.id,
  });

  const datesAndTasks = useMemo(() => {
    if (!releaseCycleSchedule) return [];

    releaseCycleSchedule.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    const firstDate = parseISO(releaseCycleSchedule[0].date);
    const lastDate = parseISO(releaseCycleSchedule[releaseCycleSchedule.length - 1].date);

    const weeks = Math.ceil((lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24 * 7) + 1);

    const weeksArray = Array.from({ length: weeks }, (_, index) => {
      const weekStart = startOfWeek(addDays(firstDate, index * 7), { weekStartsOn: 1 });
      const year = new Date(weekStart).getUTCFullYear();
      const month = new Date(weekStart).getUTCMonth();
      const dayDay = new Date(weekStart).getUTCDate();
      const formattedDay = new Date(Date.UTC(year, month, dayDay));

      const weekEnd = addDays(formattedDay, 6);

      return {
        startDate: weekStart,
        endDate: weekEnd,
        tasks: releaseCycleSchedule.filter((customDate) => {
          const utcDate = parseISO(customDate.date);
          const localDate = utcToZonedTime(utcDate, timeZone);
          const localFormattedDate = format(localDate, 'yyyy-MM-dd');

          return (
            isSameDay(new Date(localFormattedDate), formattedDay) ||
            isSameDay(new Date(localFormattedDate), addDays(formattedDay, 1)) ||
            isSameDay(new Date(localFormattedDate), addDays(formattedDay, 2)) ||
            isSameDay(new Date(localFormattedDate), addDays(formattedDay, 3)) ||
            isSameDay(new Date(localFormattedDate), addDays(formattedDay, 4)) ||
            isSameDay(new Date(localFormattedDate), addDays(formattedDay, 5)) ||
            isSameDay(new Date(localFormattedDate), weekEnd)
          );
        }),
      };
    });
    return weeksArray;
  }, [releaseCycleSchedule, timeZone]);

  const updateList = useCallback(() => {
    if (!releaseCycleSchedule) return;
    refetchReleaseCycleSchedule();
    refetchReleaseCyclesCompletionStats();
  }, [refetchReleaseCycleSchedule, refetchReleaseCyclesCompletionStats, releaseCycleSchedule]);

  useEffect(() => {
    if (releaseCycleSchedule) {
      setCompletedScheduleLoading(false);
    }
  }, [releaseCycleSchedule]);

  useEffect(() => {
    if (releaseCyclesCompletionStats) {
      setScheduleTasksLoading(false);
    }
  }, [releaseCyclesCompletionStats]);

  if (completedScheduleLoading && scheduleTasksLoading) {
    return (
      <div className="centered-loading mt100">
        <Loading />
      </div>
    );
  }

  if (completedScheduleLoading) {
    return (
      <div className="centered-loading mt100">
        <Loading />
      </div>
    );
  }

  return (
    <div className="mt20 mb20">
      {datesAndTasks?.map((weekAndTasks, index) => {
        return (
          <Accordion
            key={index}
            className="mt10 p0 no-bg"
            defaultExpanded={isSameDay(weekAndTasks.startDate, startOfWeek(new Date(), { weekStartsOn: 1 }))}
          >
            <AccordionSummary
              onClick={() => setWeekExpanded({ ...weekExpanded, [index]: !weekExpanded[index] })}
              className="mt0 mb10 d-flex gap20"
              expandIcon={<Icon className="accordion-expand-icon text-white">expand_more</Icon>}
            >
              <div>
                <h5 className="text-left">{t('RELEASE-CYCLES.WEEK') + ' ' + (index + 1)}</h5>
                <p className="text-faded small">
                  {format(weekAndTasks.startDate, 'do LLL') + ' - ' + format(weekAndTasks.endDate, 'do LLL')}
                </p>
              </div>
            </AccordionSummary>
            {weekAndTasks.tasks.every((item) =>
              item.items.every((task) =>
                task.itemType === 'task'
                  ? (task as ScheduleTaskModel).isCompleted
                  : (task as ScheduleAdviceModel).isRead
              )
            ) && (
              <div className="p10 text-center text-faded">
                <p>You've completed all your tasks for this week 🎉</p>
              </div>
            )}

            {weekAndTasks.tasks.map((item) => {
              return item.items.map((task) => {
                if ((task as ScheduleAdviceModel).isRead || (task as ScheduleTaskModel).isCompleted) return null;
                return task.itemType === 'task' ? (
                  <ScheduleTaskCard
                    key={task.id}
                    date={item.date}
                    task={task as ScheduleTaskModel}
                    updateList={updateList}
                    isWeekMobile
                  />
                ) : (
                  <ScheduleAdviceCard
                    key={task.id}
                    date={item.date}
                    advice={task as ScheduleAdviceModel}
                    updateList={updateList}
                    isWeekMobile
                    hideImage
                  />
                );
              });
            })}
            {weekAndTasks.tasks.some((item) =>
              item.items.some((task) =>
                task.itemType === 'task'
                  ? (task as ScheduleTaskModel).isCompleted
                  : (task as ScheduleAdviceModel).isRead
              )
            ) && (
              <>
                {viewCompleted && (
                  <div className="d-flex jc-space-between mt10 mb10">
                    <h5 className="mt-auto mb-auto text-faded">Completed tasks</h5>
                    <Button
                      onClick={() => {
                        toggleCompleted && toggleCompleted();
                      }}
                      className="btn-grey br50p m0"
                    >
                      <Icon className="fs-16 pr8 material-symbols-outlined">visibility_off</Icon>
                      Hide
                    </Button>
                  </div>
                )}
                {!viewCompleted && (
                  <Button
                    onClick={() => {
                      toggleCompleted && toggleCompleted();
                    }}
                    className="btn-grey br50p m0 w100p"
                  >
                    <Icon className="fs-16 pr8 material-symbols-outlined">visibility</Icon>
                    Show completed tasks
                  </Button>
                )}
                {viewCompleted && (
                  <>
                    {weekAndTasks.tasks.map((item) => {
                      return item.items.map((task) => {
                        if ((task as ScheduleAdviceModel).isRead || (task as ScheduleTaskModel).isCompleted)
                          return task.itemType === 'task' ? (
                            <ScheduleTaskCard
                              key={task.id}
                              date={item.date}
                              task={task as ScheduleTaskModel}
                              updateList={updateList}
                              isWeekMobile
                            />
                          ) : (
                            <ScheduleAdviceCard
                              key={task.id}
                              date={item.date}
                              advice={task as ScheduleAdviceModel}
                              updateList={updateList}
                              isWeekMobile
                              hideImage
                            />
                          );
                        return null;
                      });
                    })}
                  </>
                )}
              </>
            )}
          </Accordion>
        );
      })}
    </div>
  );
};

export default ReleaseCycleWeekViewMobile;
