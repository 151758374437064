import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { CallBackProps as JoyrideCallBackProps, Step as JoyrideStep, TooltipRenderProps } from 'react-joyride';

import { JoyrideStepToWalkthroughStep, WalkthroughStepToJoyrideStep } from '../../formatters/WalkthroughFormatters';
import { Actions, CallBackProps, Events, Lifecycle, Status, Walkthrough } from '../../models/Walkthrough';
import { BlurOverlay } from '../utility/blur-overlay/BlurOverlay';
import Button from '../utility/microcomponents/Button';

type WalkthroughComponentProps = {
  walkthrough: Walkthrough;
  stepIndex: number;
  run: boolean;
  callback: (data: CallBackProps) => void;
};

const WalkthroughComponent: React.FC<WalkthroughComponentProps> = ({ walkthrough, stepIndex, run, callback }) => {
  const { t } = useTranslation();

  const steps: JoyrideStep[] = walkthrough.steps.map(WalkthroughStepToJoyrideStep);

  const joyrideCallBackPropsToCallBackProps = (data: JoyrideCallBackProps): CallBackProps => {
    return {
      action: data.action as Actions,
      event: data.type as Events,
      status: data.status as Status,
      index: data.index,
      lifecycle: data.lifecycle as Lifecycle,
      step: JoyrideStepToWalkthroughStep(data.step),
    };
  };

  const handleCallbackInner = (data: JoyrideCallBackProps) => {
    const callBackProps = joyrideCallBackPropsToCallBackProps(data);
    callback(callBackProps);
  };

  const CustomTooltip: React.FC<TooltipRenderProps> = ({ index, primaryProps, skipProps, step }) => {
    if (typeof step.title === 'string') {
      step.title = t(step.title);
    }
    if (typeof step.content === 'string') {
      step.content = t(step.content);
    }

    return (
      <div className="tooltip__body walkthrough-tooltip">
        <div className="d-flex jc-space-between">
          <p className="walkthrough-tooltip-progress-text text-faded">
            {t('WALKTHROUGH.NAVIGATION.PROGRESS-LABEL')} - {index + 1} of {steps.length}
          </p>
          {index < steps.length - 1 && (
            <Button isCustom {...skipProps} ariaLabel="skip-walkthrough">
              <p className="walkthrough-tooltip-skip-button">{skipProps.title}</p>
            </Button>
          )}
        </div>
        {step.title && <h4>{step.title}</h4>}
        <p className="text-faded mt4">{step.content}</p>
        <Button className="btn-white w100p m0" {...primaryProps}>
          {primaryProps.title}
        </Button>
      </div>
    );
  };

  const locale = useMemo(
    () => ({
      back: t('WALKTHROUGH.NAVIGATION.BACK'),
      close: t('WALKTHROUGH.NAVIGATION.CLOSE'),
      last: t('WALKTHROUGH.NAVIGATION.DONE'),
      next: t('WALKTHROUGH.NAVIGATION.NEXT'),
      skip: t('WALKTHROUGH.NAVIGATION.SKIP'),
    }),
    [t]
  );

  return (
    <>
      {run && (
        <BlurOverlay
          selector={walkthrough?.steps[stepIndex]?.target}
          spotlightConfig={walkthrough?.steps[stepIndex]?.spotlightConfig}
        />
      )}
      <Joyride
        tooltipComponent={CustomTooltip}
        steps={steps}
        stepIndex={stepIndex}
        run={run}
        continuous
        showSkipButton
        hideCloseButton
        disableScrolling
        disableScrollParentFix
        disableOverlayClose
        disableOverlay={true}
        locale={locale}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: '#262626',
          },
          buttonClose: {
            display: 'none',
          },
        }}
        callback={handleCallbackInner}
      />
    </>
  );
};

export default WalkthroughComponent;
