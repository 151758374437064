import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, Dialog, DialogActions, Icon, Switch } from '@mui/material';

import useAccountContext from '@/hooks/context/useAccountContext';
import AccountAPI from '@/network/AccountAPI';
import { useLocalStorage } from '@/services/LocalStorage';
import { handleApiError } from '@/utility/api';

const WalkthroughMarketingPreferences: React.FC<{ walkthroughEnded: boolean; setWalkthroughEnded: () => void }> = ({
  walkthroughEnded,
  setWalkthroughEnded,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [manualClose, setManualClose] = useState<boolean>(false);
  const [emailNotification, setEmailNotification] = useState<boolean>(false);
  const { accountId, account, refetchAccount } = useAccountContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getItem, setItem, accountId: localStorageAccountId } = useLocalStorage();

  const storageKey = 'walkthrough-marketing-preferences-dialog';
  const hasSeenDialogKey = `${storageKey}-seen-dialog`;
  const userFirstSeenKey = `${storageKey}-user-first-seen`;
  const hasSavedPreferencesKey = `${storageKey}-saved-preferences`;

  useEffect(() => {
    setEmailNotification(account?.config?.marketingAccepted || false);
  }, [account]);

  // Record when the user is first seen, even if the dialog is not shown
  useEffect(() => {
    if (!localStorageAccountId) return;

    const firstSeen = getItem(userFirstSeenKey);
    if (!firstSeen) {
      setItem(userFirstSeenKey, new Date().toISOString());
    }
  }, [getItem, localStorageAccountId, setItem, userFirstSeenKey]);

  // Record when the user has seen the dialog
  useEffect(() => {
    if (!isOpen) return;
    setItem(hasSeenDialogKey, true);
  }, [hasSeenDialogKey, isOpen, setItem]);

  // Logic for working out when to show the dialog
  useEffect(() => {
    if (manualClose) {
      setIsOpen(false);
      return;
    }
    if (!account) return;
    if (!localStorageAccountId) return;

    // If preferences are saved, do not show the dialog
    const userSavedPreferences = getItem(hasSavedPreferencesKey) ? true : false;
    if (userSavedPreferences) {
      setIsOpen(false);
      return;
    } else if (account.config?.marketingAccepted) {
      // If the user has already accepted marketing, do not show the dialog
      // then save that the user has set their preferences
      setIsOpen(false);
      setItem(hasSavedPreferencesKey, true);
      return;
    }

    // Get the last time the user was seen using the app
    let userPastTimeLimit = false;
    const firstSeen = getItem(userFirstSeenKey);
    if (firstSeen) {
      const firstSeenDate = new Date(firstSeen);
      const now = new Date();
      const diff = now.getTime() - firstSeenDate.getTime();
      if (diff > 24 * 60 * 60 * 1000) {
        userPastTimeLimit = true;
      }
    }

    // Get if the user has seen the dialog before
    const hasSeen = getItem(hasSeenDialogKey);

    // If a walkthrough has ended, show the dialog
    if (walkthroughEnded) {
      setIsOpen(true);
      return;
    }

    // If the user has not seen the dialog for over 24 hours, force it to show
    if (userPastTimeLimit && hasSeen) {
      setIsOpen(true);
      return;
    }

    // Default to closed
    setIsOpen(false);
  }, [
    account,
    getItem,
    hasSavedPreferencesKey,
    hasSeenDialogKey,
    localStorageAccountId,
    manualClose,
    setItem,
    userFirstSeenKey,
    walkthroughEnded,
  ]);

  const updateMarketingPreferences = useCallback(
    async (emailNotification: boolean) => {
      setIsLoading(true);
      try {
        if (!accountId) return;

        await AccountAPI.updateAccount({
          accountId,
          data: {
            marketingAccepted: emailNotification,
          },
        });

        await refetchAccount();
        setIsLoading(false);
      } catch (error: unknown) {
        handleApiError({
          error,
          customMessage: t('WALKTHROUGH.ERRORS.MARKETING'),
        });
        setIsLoading(false);
      }
    },
    [accountId, refetchAccount, t]
  );

  const handleSave = useCallback(async () => {
    await updateMarketingPreferences(emailNotification);
    setItem(hasSavedPreferencesKey, true);
    setManualClose(true);
    setWalkthroughEnded();
  }, [emailNotification, hasSavedPreferencesKey, setItem, setWalkthroughEnded, updateMarketingPreferences]);

  const handleSkip = useCallback(() => {
    setManualClose(true);
  }, []);

  const handleClose = useCallback(() => {
    setManualClose(true);
  }, []);

  if (!account) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Button className="icon-btn btn-back m0 mr0 ml-auto pos-abs r8 t8" aria-label="Close" onClick={handleClose}>
        <Icon>close</Icon>
      </Button>
      <h3>{t('WALKTHROUGH.MARKETING.TITLE')}</h3>
      <div className="p8 divider" />
      <p className="pb10 p0 text-faded">{t('WALKTHROUGH.MARKETING.SUMMARY')}</p>
      <div className="d-flex flex-d-col p0 m0 pb8">
        <div className="d-flex pt8 pb8">
          <p className="p0 pt8">{t('WALKTHROUGH.MARKETING.EMAIL-LABEL')}</p>
          <Switch
            disabled={isLoading}
            className={`m0 mr0 ml-auto ${emailNotification ? 'selected' : ''}`}
            checked={emailNotification}
            onClick={() => setEmailNotification((prev) => !prev)}
            value="email"
          />
        </div>
      </div>
      <DialogActions className="pl0 pr0 pb0 d-flex flex-wrap mt-auto jc-vertical">
        <Button className="pt4 btn-white w100p m0 p16" onClick={handleSave} disabled={isLoading}>
          {isLoading ? <CircularProgress size={16} /> : t('WALKTHROUGH.MARKETING.SAVE')}
        </Button>
        <div className="p8 divider" />
        <Button className="pt4 btn-black-2 w100p m0 p16" onClick={handleSkip} disabled={isLoading}>
          {t('WALKTHROUGH.MARKETING.SKIP')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalkthroughMarketingPreferences;
