import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';

import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { OnboardingSteps } from '@/models/Enums';
import { SpotifyArtistModel, SpotifyArtistSearchModel } from '@/models/Spotify';
import OnboardingAPI from '@/network/OnboardingAPI';
import UserTracking from '@/services/UserTracking';

import SpotifySearchArtist from '../../components/utility/microcomponents/SpotifySearchArtist';
import ButtonComponent from '../utility/microcomponents/Button';
import Card from '../utility/microcomponents/Card';
import DialogModal from '../utility/modals/DialogModal';

const ArtistStep = () => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const { setOnboardingStep, onboardingStep } = useOnboardingContext();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const userTracking = UserTracking;

  const { setValue, watch } = useFormContext();

  const firstName = watch('firstName');
  const artist = watch('chosenArtist');

  const [selectedArtist, setSelectedArtist] = useState<SpotifyArtistSearchModel>();
  const [chosenArtist, setChosenArtist] = useState<SpotifyArtistModel | undefined>(artist !== '' ? artist : undefined);

  const getArtistDetails = useCallback(() => {
    if (!selectedArtist) return;
    OnboardingAPI.getArtist({ spotifyArtistId: selectedArtist.id }).then((response) => {
      setChosenArtist(response.data);
    });
  }, [selectedArtist]);

  useEffect(() => {
    if (!selectedArtist) return;
    getArtistDetails();
  }, [getArtistDetails, selectedArtist]);

  useEffect(() => {
    if (!chosenArtist) {
      setValue('artistId', '');
      setValue('chosenArtist', '');
      return;
    }
    setValue('artistId', chosenArtist.id);
    setValue('chosenArtist', chosenArtist);
  }, [chosenArtist, setValue]);

  return (
    <>
      <DialogModal
        open={modalOpen}
        type={'permissions'}
        title={'DIALOGS.ONE-MORE-THING'}
        isWebsiteConfirmation={true}
        output={(output) => {
          if (output) {
            setOnboardingStep(OnboardingSteps.CHOOSE_TRACK);
          }
          setModalOpen(false);
        }}
      />
      {breakpointHit && (
        <div className="text-left ml-16">
          <Button
            className="no-bg text-lef m0 pl0"
            onClick={() => {
              setOnboardingStep(onboardingStep - 1);
            }}
          >
            <Icon>chevron_left</Icon>
            {t('COMMON.BACK')}
          </Button>
        </div>
      )}
      {!chosenArtist && (
        <h2 className={`text-center ${breakpointHit ? 'mt10' : 'mt40'} mb48 max-w500 mr-auto ml-auto`}>
          {`${t('ONBOARDING.HI')} ${firstName}! ${t('ONBOARDING.START-BY-CONNECTING-YOUR-SPOTIFY')}`}
        </h2>
      )}
      {chosenArtist && (
        <h2 className={`text-center ${breakpointHit ? 'mt10' : 'mt40'} mb48 max-w500 mr-auto ml-auto`}>
          {t('ONBOARDING.DOES-THIS-LOOK-CORRECT')}
        </h2>
      )}

      {!chosenArtist && (
        <div className="max-w400 ml-auto mr-auto mt20">
          <SpotifySearchArtist spotifyArtist={(artist: SpotifyArtistSearchModel) => setSelectedArtist(artist)} />

          <ButtonComponent
            isCustom
            className="mt20"
            data-testId="no-spotify-artist-button"
            onClick={() => {
              setOnboardingStep(OnboardingSteps.CHOOSE_TRACK);
              userTracking?.customStaticMixpanelEvent('Onboarding - Artist - No Spotify Artist', {});
            }}
          >
            <h5 data-testid="no-spotify-artist-button">{t('ONBOARDING.I-DONT-HAVE-A-SPOTIFY-ARTIST')}</h5>
          </ButtonComponent>
        </div>
      )}

      {chosenArtist && (
        <>
          <div className="card mt16 artist-onboarding-card max-w400 ml-auto mr-auto">
            <div
              className="pos-abs"
              style={{
                backgroundImage: `url(${chosenArtist?.image?.url})`,
                borderRadius: '12px 12px 12px 12px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '-50%',
                height: '180px',
                width: '100%',
                maxWidth: '400px',
              }}
            />
            <div className="inner-titles max-w400p">
              <img src={chosenArtist?.image?.url} className="br50p" alt="" />
              <div className="inner-titles p8">
                <h5 data-testid="artist-name">{chosenArtist?.name}</h5>
                {chosenArtist.genres && chosenArtist.genres.length > 0 && (
                  <div className="ml-auto mr-auto text-center d-flex flex-wrap jc-center w100p">
                    <p className="text-faded small">{t('COMMON.GENRES')}: </p>
                    {chosenArtist.genres &&
                      chosenArtist.genres.length > 0 &&
                      chosenArtist.genres.map((genre, index) => (
                        <p key={index} className="text-faded small capitalize">
                          {genre}
                          {index < chosenArtist.genres.length - 1 && ', '}
                        </p>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <ButtonComponent isCustom className="mt20" onClick={() => setChosenArtist(undefined)}>
            <h5>{t('ONBOARDING.THIS-ISNT-ME')}</h5>
          </ButtonComponent>
          <br />
        </>
      )}
      {!chosenArtist && (
        <Card
          className={`${breakpointHit ? 'mt100' : 'mt120'} mb100 ml-auto mr-auto max-w400 text-left p16 d-flex gap10`}
        >
          <div className="did-you-know-bg min-h20 min-w20 max-w20 max-h20 br50p p10 mt-auto mb-auto">
            <img src="/images/logos/spotify-logo.svg" alt="spotify" height={20} />
          </div>
          <div>
            <h5>{t('DID-YOU-KNOW.DID-YOU-KNOW')}</h5>
            <p className="text-reduced-opacity small">{t('DID-YOU-KNOW.SPOTIFY-NO-ARTIST')}</p>
          </div>
        </Card>
      )}
      {chosenArtist && (
        <Card
          className={`${breakpointHit ? 'mt50' : 'mt60'} mb100 ml-auto mr-auto max-w400 text-left p16 d-flex gap10`}
        >
          <div className="did-you-know-bg min-h20 min-w20 max-w20 max-h20 br50p p10 mt-auto mb-auto">
            <img src="/images/logos/spotify-logo.svg" alt="spotify" height={20} />
          </div>
          <div>
            <h5>{t('DID-YOU-KNOW.DID-YOU-KNOW')}</h5>
            <p className="text-reduced-opacity small">{t('DID-YOU-KNOW.SPOTIFY-ARTIST')}</p>
          </div>
        </Card>
      )}

      <div className="onboarding-footer">
        <div className="d-flex jc-end mt40">
          {!breakpointHit && (
            <Button
              className="border-btn min-w130"
              onClick={() => {
                setOnboardingStep(onboardingStep - 1);
              }}
            >
              {t('COMMON.BACK')}
            </Button>
          )}
          <Button
            className={`btn-white min-w130 ${breakpointHit ? 'w100p' : ''}`}
            disabled={!chosenArtist}
            data-testid="continue-button"
            onClick={() => {
              if (chosenArtist) {
                setModalOpen(true);
              } else {
                setOnboardingStep(OnboardingSteps.CHOOSE_TRACK);
              }
            }}
          >
            {t('COMMON.CONTINUE')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ArtistStep;
