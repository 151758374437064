import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import { Images } from '@/constants/Images';
import useArtist from '@/hooks/artist/useArtist';
import useWidget from '@/hooks/widgets/useWidget';

import PlaylistingFlowButton from '../utility/buttons/PlaylistingFlowButton';
import Card from '../utility/microcomponents/Card';

export const PlaylistPitchesWidget = () => {
  const { t } = useTranslation();
  const { artist } = useArtist();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { widget } = useWidget({ type: 'playlist-pitches' });
  const navigate = useNavigate();

  return (
    <>
      <PlaylistingFlowButton
        hideButton
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
      />
      <Card
        className="p10 widget-card playlist-pitches pos-rel text-center"
        onClick={() => {
          if (widget?.pending === 0 && widget.recentlyReviewed === 0 && artist) {
            setIsOpen(true);
            return;
          }
          navigate('/playlisting');
        }}
      >
        {widget?.pending === 0 && widget.recentlyReviewed === 0 ? (
          <img src={Images.widgetImages.playlistPitches} alt="playlisting" height={80} className="mt10" />
        ) : (
          <>
            <div className="d-flex jc-space-between inner-stats">
              <h5>{widget?.pending}</h5>
              <p>{t('WIDGETS.PENDING')}</p>
            </div>
            <div className="d-flex jc-space-between inner-stats mt4">
              <h5>{widget?.recentlyReviewed}</h5>
              <p>{t('WIDGETS.REVIEWED')}</p>
            </div>
          </>
        )}

        <div className="d-flex pos-abs b10 footer text-left">
          <div>
            <p className="fw-bold">{`${widget?.pending === 0 && widget.recentlyReviewed === 0 ? t('COMMON.PITCH-A-SONG') : t('WIDGETS.SEE-UPDATES')}`}</p>
            <p className="text-reduced-opacity">{t('WIDGETS.PLAYLIST-PITCHES')}</p>
          </div>
          <Button className="widget-button">
            <Icon>chevron_right</Icon>
          </Button>
        </div>
      </Card>
    </>
  );
};
